import { 
  Injectable,
  Inject,
  NgZone,
  PLATFORM_ID
} from "@angular/core";
import { isPlatformBrowser,DOCUMENT } from '@angular/common';
import { SingletonService } from "../services/singleton.service";
import { profileComponentService } from "../../app/component/profile-form/profile.service";
import { Router } from '@angular/router';
import * as _ from "lodash";
declare var window: any;
declare var $:any;
declare let gtag:any;
@Injectable({
providedIn: 'root'
})
export class GtmMethodService {
 newsletterchecked:any;
 picknmixorder:boolean;
 hashedEmail:string;
constructor(   
  @Inject(DOCUMENT) public dom, 
  public singletonServ: SingletonService,
  public profileServ: profileComponentService,
  private router : Router,
  @Inject(PLATFORM_ID) public platformId: Object
){}
gtmCompleteRegistration (newsletterOptIn)
{
window.dataLayer = window.dataLayer || [],
window.dataLayer.push({
  'event': 'eec.completeRegistration',
  'virtualPage': '/vpv/complete/registration/',                      
  'newsletterOptIn': newsletterOptIn
});
}

gtmPickAndMixEvent(pickandMixCategory,productAdded)
{
    window.dataLayer = window.dataLayer || [];
     window.dataLayer.push({
    'event': 'eec.pickAndMix',
    'eventCategory': 'Pick and Mix',                      
    'eventAction': pickandMixCategory,
    'eventLabel': productAdded
    });
}


gtmFilterUsage(filterCategory,filterSelection)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.Filter',
    'eventCategory': 'Filter Usage',                      
    'eventAction': filterCategory,
    'eventLabel': filterSelection
    });
}

gtmZeroSearchResults(searchTerm)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.zeroSearchResults',
    'eventCategory': 'Zero Search Results',                      
    'eventAction': searchTerm
    });
}


gtmStoreLocator(actionCarriedOut,storedetails) 
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.storeLocator',
    'eventCategory': 'Store List Names',                      
    'eventAction':actionCarriedOut,
    'eventLabel': storedetails
    });
}

gtmQuickView(actionCarriedOut,prouductName)
{
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
    'event': 'eec.quickView',
    'eventCategory': 'Quick View',                      
    'eventAction':actionCarriedOut,
    'eventLabel': prouductName
    });
}



gtmAddSample(sampleSize,sampleName)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.addSample',
    'eventCategory': 'Add Sample',                      
    'eventAction': sampleSize,
    'eventLabel': sampleName
    });
}

gtmPageCategorisation(pageDetails,pageTypes){
  if(this.singletonServ.catalogVersion){
    window.dataLayer = window.dataLayer || [];
    const _pageCategory:any={
      'event': 'eec.PageCategorisation',
      "pageDetails":[] 
    };
const _pageDetails = [];
pageDetails = this.singletonServ;
const _baseSite = this.singletonServ.catalogVersion;
if(_baseSite){
const _objProduct={
  'pageType': pageTypes,
  'country': pageDetails.catalogVersion.isoCode,
  'userID': '',
  'userType': '',
  'pagePath':this.router.url,
  'loginStatus': (pageDetails.loggedIn)?(pageDetails.loggedIn):''
}

_pageDetails.push(_objProduct);
_pageCategory['pageDetails'] =_pageDetails;
if (this.getBrowserSessionData(_baseSite.reg)) {
  const user:any = JSON.parse(this.getBrowserSessionData(_baseSite.reg));
  let mbcustomer;
  if(user.mbcustomer > 0){
    _objProduct['userType']='Customer'
  }
  else{
    _objProduct['userType']='Prospect'
  }
  _objProduct['userID']=(user.customerId)?(user.customerId):'';
  if(user.email) this.digestMessage(user.email).then( resp => {  
    _objProduct['email'] = resp; 
    window.dataLayer.push(_pageCategory);
  });
}else{
  _objProduct['userID']='';
  _objProduct['userType']='Prospect'
  window.dataLayer.push(_pageCategory);

}
}
}
}

gtmBlogSection(category,editorialTitle,readingTime)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'eec.editorial',
    'editorialCategory': category,
    'editorialTitle': editorialTitle,
    'editorialReadingtime': readingTime
    
    });

}
storeGtmData(value){
this.newsletterchecked=value;
}

gtmPromoImpressions(promotions)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.promotionView',
    'ecommerce': {
        'promoView': {
        'promotions':[]
        }
    }
    });
  
}

loadPromotion:Array<any>=[];
gtmScrollPromoImpressions(promotions)
{
   let _checkFilter=_.uniq(this.loadPromotion);
     _checkFilter=this.loadPromotion.filter((item)=>{
       return item.id==promotions.id;
    });
    if(_checkFilter.length ==0){
    this.loadPromotion.push(promotions);
      const _obj:any={
          'event': 'eec.promotionView',
          'ecommerce': {
              'promoView': {
              'promotions':this.loadPromotion
              }
          }
          }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(_obj);
  }
}



sendFeedOnHover(promotions){
 const _obj:any={
   'event': 'eec.promotionView',
   'ecommerce': {
     'promoView': {
        'promotions':promotions
        }
     }
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(_obj);
}


gtmProductListPages(currencyCode,impressions) {
    let _productListPage={
      'event': 'eec.productImpression',
      'ecommerce': {
          'currencyCode': currencyCode,                      
          'impressions': impressions
        }
      }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(_productListPage);
}



gtmProductClick(productClickDetails)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.productClick',
    'ecommerce': {
        'currencyCode': productClickDetails.currencyCode,                      
        'click':{
        'actionField': {'list': productClickDetails.categoryList},
        'products': [{
        'name': productClickDetails.productName,      
        'id': productClickDetails.productID,
        'price': (productClickDetails.productPrice).toString(),
        'brand': productClickDetails.productBrand,
        'category': productClickDetails.productCategory,
        'list':productClickDetails.categoryList,
        'variant': productClickDetails.productVariant,
        'position': productClickDetails.position,
        'dimension3': (productClickDetails.size)?productClickDetails.size:"",
        'dimension4': productClickDetails.reviewRating,
        'dimension5': productClickDetails.reviewsCount,
        'dimension6': productClickDetails.saleStatus,
        'dimension12': productClickDetails.stockLevel,    
        'dimension13': (productClickDetails.productStockLevelNumber)?productClickDetails.productStockLevelNumber:'', 
        'dimension14': productClickDetails.deleveryType,
        'metric2': (productClickDetails.salePrice).toString()
    
        }]
        }
    }
    });
}



gtmProductDetailPages(productdetails)
{
    window.dataLayer = window.dataLayer || [];
    let priceWithQuotes = (productdetails.productPrice).toString();
    window.dataLayer.push({
    'event': 'eec.productDetail',
    'fbEvent': 'ViewContent',
    'fbEventID': productdetails.productID,
    'ecommerce': {
        'currencyCode': productdetails.currencyCode,                      
        'detail': {
        'actionField': { 'list': productdetails.actionList },
        'products':[{
            'name': productdetails.productName,      
            'id': productdetails.productID,
            'price': priceWithQuotes,
            'brand': productdetails.productBrand,
            'category': productdetails.productCategory,
            'variant': productdetails.productVariant,
            'dimension3': (productdetails.size)?productdetails.size:"",
            'dimension4': productdetails.reviewRating,
            'dimension5': productdetails.reviewsCount,
            'dimension6': productdetails.saleStatus,
            'dimension12': productdetails.stockLevel,    
            'dimension13': (productdetails.productStockLevelNumber)?productdetails.productStockLevelNumber:'',
            'dimension14': productdetails.deleveryType,
            'metric2': (productdetails.salePrice).toString()
        }]
        }
    }
    });

}

// method to hash email id using SHA-256
async digestMessage(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));              
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}


gtmAddToCart(cartDetails) {
  const baseSite = this.singletonServ.cartObj;
  this.digestMessage(cartDetails.email).then( resp => {  
    this.hashedEmail = resp;    
    console.log("hashedEmail email", this.hashedEmail);
    let cartId =  baseSite?.code;
    window.dataLayer = window.dataLayer || [];
    let priceWithQuotes = (cartDetails.productPrice).toString();
    window.dataLayer.push({
    'event': 'eec.addToCart',
    'fbEvent': 'AddToCart',
    'fbEventID': cartId,
    'ecommerce': {
    'currencyCode': cartDetails.currencyCode,                      
    'add': {
        'actionField': { 'list': cartDetails.categoryList },
        'products':[{
        'name': (cartDetails.productName)?cartDetails.productName: '',      
        'id': (cartDetails.productID)?cartDetails.productID: '',
        'price': (priceWithQuotes)?priceWithQuotes: '',
        'brand': (cartDetails.productBrand)?cartDetails.productBrand: '',
        'category': (cartDetails.productCategory)?cartDetails.productCategory: '',
        'variant': (cartDetails.productVariant)?cartDetails.productVariant:'',
        'quantity':(cartDetails.productQuantity)?cartDetails.productQuantity:'',
        'email': (this.hashedEmail)?this.hashedEmail:'',
        'dimension3': (cartDetails.size)?cartDetails.size:'',
        'dimension4': (cartDetails.reviewRating)?cartDetails.reviewRating:'',
        'dimension5': (cartDetails.reviewsCount)? cartDetails.reviewsCount: 0,
        'dimension6': (cartDetails.saleStatus)?cartDetails.saleStatus:'',
        'dimension12': (cartDetails.stockLevel)?cartDetails.stockLevel:'',    
        'dimension13': (cartDetails.productStockLevelNumber)?cartDetails.productStockLevelNumber:'',
        'dimension14': (cartDetails.deleveryType)?cartDetails.deleveryType:'',
        'metric2': (cartDetails.salePrice)?(cartDetails.salePrice).toString():''
        }]
    }
    }
    });
  }); 
}



gtmBasketAddtoCart(cartDetails)
{
  const baseSite = this.singletonServ.cartObj;
  let cartId =  baseSite.code;
    window.dataLayer = window.dataLayer || [];
    let item = {
      'event': 'eec.addToCart',
      'fbEvent': 'AddToCart',
      'fbEventID': cartId,
      'ecommerce': {
          'currencyCode': cartDetails.currencyCode,                      
          'remove': {
          'actionField': { 'list': cartDetails.categoryList },
          'products':[]
          }
      }
      }
      for(let i=0; i<cartDetails.products.length; i++){
          item.ecommerce.remove.products.push({
              'name': cartDetails.products[i].productName,      
              'id': cartDetails.products[i].productID,
              'price': (cartDetails.products[i].productPrice).toString(),
              'brand': cartDetails.products[i].productBrand,
              'category': cartDetails.products[i].productCategory,
              'variant': cartDetails.products[i].productVariant,
              'quantity': cartDetails.products[i].productQuantity,
              'dimension3': cartDetails.products[i].size,
              'dimension4': cartDetails.products[i].reviewRating,
              'dimension5': cartDetails.products[i].reviewsCount,
              'dimension6': cartDetails.products[i].saleStatus,
              'dimension12': cartDetails.products[i].stockLevel,    
              'dimension13': (cartDetails.products[i].productStockLevelNumber)?cartDetails.products[i].productStockLevelNumber:'',
              'dimension14': cartDetails.products[i].deleveryType,
              'metric2': cartDetails.products[i].salePrice
          })
      }
    window.dataLayer.push(item);
}

gtmRemoveFromCart(cartDetails)
{
    window.dataLayer = window.dataLayer || [];
    let priceWithQuotes = (cartDetails.productPrice).toString();
    let item = {
      'event': 'eec.removeFromCart',
      'ecommerce': {
          'currencyCode': cartDetails.currencyCode,                      
          'remove': {
          'actionField': { 'list': cartDetails.categoryList },
          'products':[]
          }
      }
      }
          item.ecommerce.remove.products.push({
              'name': cartDetails.productName,      
              'id': cartDetails.productID,
              'price': priceWithQuotes,
              'brand': cartDetails.productBrand,
              'category': cartDetails.productCategory,
              'variant': cartDetails.productVariant,
              'quantity': cartDetails.productQuantity,
              'dimension3': cartDetails.size,
              'dimension4': cartDetails.reviewRating,
              'dimension5': cartDetails.reviewsCount,
              'dimension6': cartDetails.saleStatus,
              'dimension12': cartDetails.stockLevel,    
              'dimension13': cartDetails.productStockLevelNumber,
              'dimension14': cartDetails.deleveryType,
              'metric2': (cartDetails.salePrice).toString()
          })
      
    window.dataLayer.push(item);
}
gtmRemoveRichCart(cartDetails)
{
    window.dataLayer = window.dataLayer || [];
    let item = {
      'event': 'eec.removeFromCart',
      'ecommerce': {
          'currencyCode': cartDetails.currencyCode,                      
          'remove': {
          'actionField': { 'list': cartDetails.categoryList },
          'products':[]
          }
      }
      }
      item.ecommerce.remove.products.push({
        'name': cartDetails.products[0].productName,      
        'id': cartDetails.products[0].productID,
        'price': (cartDetails.products[0].productPrice).toString(),
        'brand': cartDetails.products[0].productBrand,
        'category': cartDetails.products[0].productCategory,
        'variant': cartDetails.products[0].productVariant,
        'quantity': cartDetails.products[0].productQuantity,
        'dimension3': cartDetails.products[0].size,
        'dimension4': cartDetails.products[0].reviewRating,
        'dimension5': cartDetails.products[0].reviewsCount,
        'dimension6': cartDetails.products[0].saleStatus,
        'dimension12': cartDetails.products[0].stockLevel,    
        'dimension13': cartDetails.products[0].productStockLevelNumber,
        'dimension14': cartDetails.products[0].deleveryType,
        'metric2': (cartDetails.products[0].salePrice).toString()
    })
      
    window.dataLayer.push(item);
}
gtmPickandMix(item,travelBag)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.pickandMixStep',
    'virtualPage': item.stepName,
    'travelBag': travelBag                    
    });
}

gtmSetPickandMixSteps(vrtlPage:string,trvlBag:any){
  const item ={
      'stepName':vrtlPage
  }
  this.gtmPickandMix(item,trvlBag);
}
gtmCheckoutSteps(checkoutDetails,item){
  const baseSite = this.singletonServ.cartObj;
  let cartId =  baseSite.code;
  window.dataLayer = window.dataLayer || [];
  const _dataLayer={
    'event': 'eec.checkout',
    'fbEvent': 'InitiateCheckout',
    'fbEventID': cartId,
    'ecommerce': {
        'currencyCode': item.currencyCode,    
        'checkoutsubtotal':item.checkoutSubtotal,                  
        'checkout': {
        'actionField':{ 'step': item.stepNumber },
        'products':checkoutDetails
        }
    }
    }
  window.dataLayer.push(_dataLayer);
}
gtmSetFeed(cartObj:any,step:string){
  const singletonVariable= cartObj.entries;
  const singletonVariableMain=cartObj;
  const item ={
  'currencyCode':singletonVariableMain.totalPrice.currencyIso,
  'stepNumber':step,
  'checkoutSubtotal':cartObj.subTotal.value.toString()
  };
  const checkoutDetails=[];
  singletonVariable.map((obj)=>{
  const productUrl = obj.product;
  let pageUrlCat;
  let _subCatg;

  if(productUrl.length){
      productUrl.map((prod)=>{
          let categoryUrl= this.retireveCatpath(prod.product)
          
          checkoutDetails.push(this.addProducts(prod,categoryUrl[2], singletonVariableMain)); // [name1,name2]
      })
  } else {
       pageUrlCat= this.retireveCatpath(productUrl)
       if(pageUrlCat[2]=="bath-body-gift-sets" || pageUrlCat[2]=="body-lotion-cream" || pageUrlCat[2]=="bath-shower-gel"){
         if(pageUrlCat[2]=="bath-body-gift-sets"){
          let urlsplit:any=pageUrlCat[2].split("-")
          let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]+" "+urlsplit[3]
          _subCatg=subcat.toUpperCase()
         }
         else if(pageUrlCat[2]=="body-lotion-cream"){
          let urlsplit:any=pageUrlCat[2].split("-")
          let subcat=urlsplit[0]+" "+urlsplit[1]+" & "+urlsplit[2]
          _subCatg=subcat.toUpperCase()
         }
         else if(pageUrlCat[2]=="bath-shower-gel"){
          let urlsplit:any=pageUrlCat[2].split("-")
          let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]
          _subCatg=subcat.toUpperCase()
         }

       }
       else{
        let subcat=pageUrlCat[2].split("-").join(" ");
        _subCatg=subcat.toUpperCase()
       }
       checkoutDetails.push(this.addProducts(obj,_subCatg, singletonVariableMain)); 
  }
  });
  this.gtmCheckoutSteps( checkoutDetails,item);
}

addProducts(obj, _subCatg, singletonVariableMain){
  const _obj={
      'name': obj.product.productDisplayName,     
      'id': obj.product.code,
      'price': '',
      'brand': "Molton Brown",
      'category': (obj.bundleTemplateId && obj.bundleTemplateId.includes("GiftBuilder")) ?  'gift-builder': _subCatg,
      'variant': (obj.product.productVariant)?obj.product.productVariant:'',
      'quantity':obj.quantity,
      'dimension3': (obj.product.size)?obj.product.size:"",
      'dimension4': (obj.product.productAverageRating)?obj.product.productAverageRating:'0',
      'dimension5': (obj.product.reviewCount)?obj.product.reviewCount:'0',
      'dimension6': (obj.product.productVariant)?'True':'False',
      'dimension7': obj.product.isSample,
      'dimension8': singletonVariableMain.isGiftBox,
      'dimension9': (singletonVariableMain.isGiftBoxMessage)?'True':'False',
      'dimension12': (obj.product.stock.stockLevelStatus == "inStock")? 'True' : 'False',    
      'dimension13': (obj.product.stock.stockLevel)?(obj.product.stock.stockLevel):'',
      'dimension14': (obj.product.productEdition)?obj.product.productEdition:'', 
      'metric2': (obj.product.originalPrice)?(obj.product.price.value).toString():''
  }
  if(obj.product.originalPrice){
      const originalPrice=obj.product.originalPrice;
      const _originalPrice = originalPrice.match(/[\d\.]+/);
      if(_originalPrice){
        _obj['price']=_originalPrice[0];
      }else{
        _obj['price']=originalPrice;
      }
      }
      else if (obj.product.code.includes("giftbuilder") && obj.basePrice.value) {
        _obj['price']= obj.basePrice.value;
      }else{
        _obj['price']=obj.product.price.value;
      }
      _obj['price'] = _obj['price'].toString();
  return _obj;      
  
}

retireveCatpath(dataurl){
  if(dataurl.category){

      const _url= dataurl.category.url;
      return _url.split("/");
     }else if(dataurl.url){
      const _url= dataurl.url;
      return _url.split("/");
     }
}
gtmPurchasepicknmix(purchaseDetails:any){
    window.dataLayer = window.dataLayer || [];
    let vocherCode:any
    const guest =purchaseDetails.guestCustomer;
    if(purchaseDetails.appliedVouchers[0]==undefined){
      vocherCode='';
    }
    else{
      vocherCode=purchaseDetails.appliedVouchers[0].voucherCode;
    }
    const _purchaseObj:any={
        
      'event': 'eec.purchase',
      'fbEvent': 'Purchase',
      'fbEventID': purchaseDetails.code,
      'checkoutType':(guest == true)? 'Guest': 'Registered',
      'shippingCountry':purchaseDetails.deliveryAddress.country.isocode,
      'ecommerce': {
      'currencyCode':  purchaseDetails.totalPrice.currencyIso,
      'purchase': {
          'actionField': {
              'id': purchaseDetails.code,                         
              'affiliation': purchaseDetails.store,
              'revenue': purchaseDetails.totalPrice.value,                    
              'tax':purchaseDetails.totalTax.value,
              'shipping': purchaseDetails.deliveryCost.value,
              'coupon':vocherCode,
              'newsletterOptIn':(this.newsletterchecked)?(this.newsletterchecked):'false',
              'metric3': (purchaseDetails.totalDiscounts.value)?(purchaseDetails.totalDiscounts.value):'0',
              
          },
          "products":[]           
          }
      }
      };
      const _products=[];
      if(purchaseDetails.entries.length > 1){
        purchaseDetails.entries.map((obj)=>{
          const productUrl = obj.product;
          const pageUrlCat = this.retireveCatpath(productUrl);
          let _subCatg:any;
          if(obj.bundleTemplateId && (obj.bundleTemplateId.includes('GiftBuilder_Pick') || obj.bundleTemplateId.includes('PickandMix_Pick'))){
            _subCatg=obj.bundleTemplateId;
          }
          else if(undefined == pageUrlCat){
            _subCatg=""
         }
         else{
          if(pageUrlCat[2]=="bath-body-gift-sets" || pageUrlCat[2]=="body-lotion-cream" || pageUrlCat[2]=="bath-shower-gel"){
            if(pageUrlCat[2]=="bath-body-gift-sets"){
             let urlsplit:any=pageUrlCat[2].split("-")
             let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]+" "+urlsplit[3]
             _subCatg=subcat.toUpperCase()
           
            }
            else if(pageUrlCat[2]=="body-lotion-cream"){
             let urlsplit:any=pageUrlCat[2].split("-")
             let subcat=urlsplit[0]+" "+urlsplit[1]+" & "+urlsplit[2]
             _subCatg=subcat.toUpperCase()
            }
            else if(pageUrlCat[2]=="bath-shower-gel"){
             let urlsplit:any=pageUrlCat[2].split("-")
             let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]
             _subCatg=subcat.toUpperCase()
            }
 
          }
          else{
           let subcat=pageUrlCat[2].split("-").join(" ");
           _subCatg=subcat.toUpperCase()
          }
        }
          const _obj={
                    'name': (obj.bundleTemplateId)?(obj.bundleTemplateId):(obj.product && obj.product.productDisplayName ? obj.product.productDisplayName : ''),     
                    'id': (obj.product.code)?(obj.product.code):'',
                    'price': (obj.bundlePrice)?((obj.bundlePrice).toString()):'', 
                    'brand': "Molton Brown",
                    'category': _subCatg,
                    'variant': (obj.productVariant)?obj.productVariant:'',
                    'quantity':(obj.quantity)?(obj.quantity):'',
                    'dimension3': (obj.product.size)?obj.product.size:"",
                    'dimension4': (obj.product.productAverageRating)?obj.product.productAverageRating:'',
                    'dimension5': (obj.product.reviewCount)?obj.product.reviewCount:'',
                    'dimension6': (obj.product.productVariant)?'True':'False',
                    'dimension7': (obj.product.isSample)?(obj.product.isSample):'',
                    'dimension8': (purchaseDetails.isGiftBox)?(purchaseDetails.isGiftBox):'',
                    'dimension9': (purchaseDetails.isGiftBoxMessage)?purchaseDetails.isGiftBoxMessage:'',
                    'dimension10': (purchaseDetails.deliveryMode.serviceName)?(purchaseDetails.deliveryMode.serviceName):'',
                    'dimension12': 'True',    
                    'dimension13': '',
                    'dimension14': (obj.product.productEdition)?obj.product.productEdition:'' ,
                    'metric2': (obj.product.originalPrice)?obj.product.price.value:'',
                    'metric4': (obj.totalPrice)?(obj.totalPrice.value).toString():'',
                    'metric5': (obj.discountedPrice)?(obj.discountedPrice).toString():''
          }
          if(obj.product.originalPrice){
              const originalPrice= obj.product.originalPrice;
              const _originalPrice = originalPrice.match(/[\d\.]+/);
              if(_originalPrice){
                  _obj['price']=_originalPrice[0];
              }else{
                  _obj['price']=originalPrice;
              }
              }
              else if(obj.basePrice){
                _obj['price']=obj.basePrice.value;
              }
              //updates for Giftbuilder & picknmix 
              else  if(obj.bundleTemplateId && (obj.bundleTemplateId.includes('GiftBuilder_Pick') || obj.bundleTemplateId.includes('PickandMix_Pick'))){
                if(obj.bundlePrice && obj.bundleTemplateId.includes('PickandMix_Pick')){
                  _obj['price']=obj.bundlePrice;
                 }
                 else{
                  for(let i=0; i<obj.product.length; i++){
                      _obj['price']= obj.product[i].basePrice.value;                              
                  }
                 }
                _obj.id= obj.bundleTemplateId;
              }
              else{
                for(let i=0; i<obj.product.length; i++){
                  _obj['price']= obj.product[i].basePrice.value;                              
              }
              }
              _obj['price']=(_obj['price']).toString();
              console.log("price "+_obj['price']);
          _products.push(_obj);
          });
          _purchaseObj.ecommerce.purchase.products=_products;
          window.dataLayer.push(_purchaseObj);


      }
      else{
        purchaseDetails.entries.map((obj)=>{
          const productUrl = obj.product;
          const pageUrlCat = this.retireveCatpath(productUrl);
          let _subCatg:any;
           _subCatg=obj.bundleTemplateId;
          const _obj={
                    'name': obj.bundleTemplateId,     
                    'id': obj.product[0].product.code,
                    'price': obj.bundlePrice,
                    'brand': "Molton Brown",
                    'category': _subCatg,
                    'variant': (obj.productVariant)?obj.productVariant:'',
                    'quantity':obj.quantity,
                    'dimension3': (obj.product[0].product.size)?obj.product[0].size:"",
                    'dimension4': (obj.product[0].product.productAverageRating)?obj.product[0].product.productAverageRating:'',
                    'dimension5': (obj.product[0].product.reviewCount)?obj.product[0].product.reviewCount:'',
                    'dimension6': (obj.product[0].product.productVariant)?'True':'False',
                    'dimension7': obj.product[0].product.isSample,
                    'dimension8': purchaseDetails.isGiftBox,
                    'dimension9': (purchaseDetails.isGiftBoxMessage)?purchaseDetails.isGiftBoxMessage:'',
                    'dimension10': (purchaseDetails.deliveryMode.serviceName)?(purchaseDetails.deliveryMode.serviceName):'',
                    'dimension12': (obj.stock.stockLevelStatus == "inStock")? 'True' : 'False',    
                    'dimension13': (obj.stock.stockLevel)?(obj.stock.stockLevel):'',
                    'dimension14': (obj.product[0].product.productEdition)?obj.product[0].product.productEdition:'' ,
                    'metric2': (obj.product[0].product.originalPrice)?obj.product[0].product.price.value:'',
                    'metric4': (obj.totalPrice)?obj.totalPrice.value:0,
                    'metric5': (obj.discountedPrice)?obj.discountedPrice:0
          }
          if(obj.product.originalPrice){
              const originalPrice= obj.product.originalPrice;
              const _originalPrice = originalPrice.match(/[\d\.]+/);
              if(_originalPrice){
                  _obj['price']=_originalPrice[0];
              }else{
                  _obj['price']=originalPrice;
              }
              }
              else if(obj.basePrice){
                _obj['price']=obj.basePrice.value;
              }
              //updates for Giftbuilder & picknmix 
              else  if(obj.bundleTemplateId && (obj.bundleTemplateId.includes('GiftBuilder_Pick') || obj.bundleTemplateId.includes('PickandMix_Pick'))){
                if(obj.bundlePrice && obj.bundleTemplateId.includes('PickandMix_Pick')){
                  _obj['price']=obj.bundlePrice;
                 }
                 else{
                  for(let i=0; i<obj.product.length; i++){
                      _obj['price']= obj.product[i].basePrice.value;                              
                  }
                 }
                _obj.id= obj.bundleTemplateId;  
              }
              else{
                for(let i=0; i<obj.product.length; i++){
                  _obj['price']= obj.product[i].basePrice.value;                              
              }
            }
          _products.push(_obj);
          });
          _purchaseObj.ecommerce.purchase.products=_products;
          window.dataLayer.push(_purchaseObj);
      } 
}
async gtmPurchase(purchaseDetails:any){
  if(purchaseDetails.bundleTemplateId == "PickandMix_Pick6" || purchaseDetails.bundleTemplateId == "PickandMix_Pick3" || purchaseDetails.bundleTemplateId == "PickandMix_Pick2" || purchaseDetails.bundleTemplateId == "GiftBuilder_Pick3" || purchaseDetails.bundleTemplateId == "GiftBuilder_Pick4" || purchaseDetails.bundleTemplateId == "GiftBuilder_Pick5" || purchaseDetails.bundleTemplateId == "GiftBuilder_Pick6"){
    const purchaseobj=purchaseDetails;
    this.gtmPurchasepicknmix(purchaseobj);
  }
  else{
    window.dataLayer = window.dataLayer || [];
    let vocherCode:any
    const guest =purchaseDetails.guestCustomer;
    if(purchaseDetails.appliedVouchers[0]==undefined){
      vocherCode='';
    }
    else{
      vocherCode=purchaseDetails.appliedVouchers[0].voucherCode;
    }
    const _purchaseObj:any={
        
      'event': 'eec.purchase',
      'fbEvent': 'Purchase',
      'fbEventID': purchaseDetails.code,
      'checkoutType':(guest == true)? 'Guest': 'Registered',
      'shippingCountry':purchaseDetails.deliveryAddress.country.isocode,
      'ecommerce': {
      'currencyCode':  purchaseDetails.totalPrice.currencyIso,
      'purchase': {
          'actionField': {
              'id': purchaseDetails.code,                         
              'affiliation': purchaseDetails.store,
              'revenue': (purchaseDetails.totalPrice.value).toString(),                    
              'tax':purchaseDetails.totalTax.value,
              'shipping': purchaseDetails.deliveryCost.value,
              'coupon':vocherCode,
              'newsletterOptIn':(this.newsletterchecked)?(this.newsletterchecked):'false',
              'metric3': (purchaseDetails.totalDiscounts.value)?(purchaseDetails.totalDiscounts.value):'0',
             
          },
          "products":[]            
          }
      }
      };
      const _products=[]; 
      purchaseDetails.entries.map((obj)=>{
          const productUrl = obj.product;
          const pageUrlCat = this.retireveCatpath(productUrl);
          let _subCatg:any;
          if(undefined == pageUrlCat){
            _subCatg=""
        }
        else{
          
          if(pageUrlCat[2]=="bath-body-gift-sets" || pageUrlCat[2]=="body-lotion-cream" || pageUrlCat[2]=="bath-shower-gel"){
            if(pageUrlCat[2]=="bath-body-gift-sets"){
             let urlsplit:any=pageUrlCat[2].split("-")
             let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]+" "+urlsplit[3]
             _subCatg=subcat.toUpperCase()
           
            }
            else if(pageUrlCat[2]=="body-lotion-cream"){
             let urlsplit:any=pageUrlCat[2].split("-")
             let subcat=urlsplit[0]+" "+urlsplit[1]+" & "+urlsplit[2]
             _subCatg=subcat.toUpperCase()
            }
            else if(pageUrlCat[2]=="bath-shower-gel"){
             let urlsplit:any=pageUrlCat[2].split("-")
             let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]
             _subCatg=subcat.toUpperCase()
            }
 
          }
          else{
           let subcat=pageUrlCat[2].split("-").join(" ");
           _subCatg=subcat.toUpperCase()
          }
        }

          const _obj={
                    'name': (obj.product.productDisplayName)?(obj.product.productDisplayName):obj.bundleTemplateId,     
                    'id': (obj.bundleTemplateId == "PickandMix_Pick2" || obj.bundleTemplateId == "PickandMix_Pick3" || obj.bundleTemplateId == "PickandMix_Pick6") ? obj.bundleTemplateId: ((obj.product.code)?(obj.product.code):obj.product[0].product.code),
                    'price': (obj.bundlePrice)?((obj.bundlePrice).toString()):"",
                    'brand': "Molton Brown",
                    'category': _subCatg,
                    'variant': (obj.product.productVariant)?obj.product.productVariant:'',
                    'quantity':obj.quantity,
                    'dimension3': (obj.product.size)?obj.product.size:"",
                    'dimension4': (obj.product.productAverageRating)?obj.product.productAverageRating:'',
                    'dimension5': (obj.product.reviewCount)?obj.product.reviewCount:'',
                    'dimension6': (obj.product.productVariant)?'True':'False',
                    'dimension7': obj.product.isSample,
                    'dimension8': purchaseDetails.isGiftBox,
                    'dimension9': (purchaseDetails.isGiftBoxMessage)?purchaseDetails.isGiftBoxMessage:'',
                    'dimension10': (purchaseDetails.deliveryMode.serviceName)?(purchaseDetails.deliveryMode.serviceName):'',
                    'dimension12': '',    
                    'dimension13': '',
                    'dimension14': (obj.product.productEdition)?obj.product.productEdition:'' ,
                    'metric2': (obj.product.originalPrice)?(obj.product.price.value).toString():'',
                    'metric4': (obj.totalPrice)?(obj.totalPrice.value).toString():'',
                    'metric5': (obj.discountedPrice)?(obj.discountedPrice).toString():''
          }

           if(undefined == obj.product.stock){
            _obj['dimension12']=""
          }
          else if(obj.product.stock.stockLevelStatus == "inStock"){
            _obj['dimension12']="True"
          }
          else{
            _obj['dimension12']="False"
          }

          if(undefined == obj.product.stock){
            _obj['dimension13']=""
          }
          else if(obj.product.stock.stockLevel){
            _obj['dimension13']=obj.product.stock.stockLevel
          }
          else{
            _obj['dimension13']=""
          }

          

          if(obj.product.originalPrice){
              const originalPrice= obj.product.originalPrice;
              const _originalPrice = originalPrice.match(/[\d\.]+/);

               if(_originalPrice){
                  _obj['price']=_originalPrice[0];
              }else{
                  _obj['price']=originalPrice;
              }
              }
              else if(obj.basePrice){
                _obj['price']=obj.basePrice.value;
              }
              else if(purchaseDetails.bundleTemplateId == "PickandMix_Pick6" || purchaseDetails.bundleTemplateId == "PickandMix_Pick3" || purchaseDetails.bundleTemplateId == "PickandMix_Pick2"){
               if(obj.bundlePrice){
                _obj['price']=obj.bundlePrice;
               }
               else{
                for(let i=0; i<obj.product.length; i++){
                    _obj['price']= obj.product[i].basePrice.value;                              
                }
               }
               
              }
              else{
                for(let i=0; i<obj.product.length; i++){
                  _obj['price']= obj.product[i].basePrice.value;                              
              }
              }
              _obj['price']= _obj['price'].toString();
          _products.push(_obj);
          });
          _purchaseObj.ecommerce.purchase.products=_products;
          window.dataLayer.push(_purchaseObj);
        }      
}
gtmPromoclicks(promotions)
{
   window.dataLayer = window.dataLayer || [];
   const _data={
      'event': 'eec.promotionClick',
      'ecommerce': {
          'promoClick': {
          'promotions': []
         }
      }
      };
      _data.ecommerce.promoClick.promotions.push(promotions);
        window.dataLayer.push(_data);
}
gtmAccordionClick(accordionInfo)
{
   window.dataLayer = window.dataLayer || [];
   const _accordionData={
      'event': 'accordionClick',
      'eventCategory': accordionInfo.category,
      'eventAction': accordionInfo.accordionSectionText,
      'eventLabel': accordionInfo.label
      };
      window.dataLayer.push(_accordionData);
}
gtmCtaClick(ctaClickInfo:any)
{
   window.dataLayer = window.dataLayer || [];
   const _ctaClickData={
      'event': 'cta',
      'eventCategory': ctaClickInfo.ctaCategory,
      'eventAction': ctaClickInfo.ctaButtonText,
      'eventLabel': ctaClickInfo.ctaLabel
      };
      window.dataLayer.push(_ctaClickData);
}
getUserData(tokenId, id) {
  const baseSite = this.singletonServ.catalogVersion;
  const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
  this.profileServ.getUserData(baseSite,tokenId, id).subscribe(
    (resp:any) => {
      
      this.setBrowserSessionData(baseSite.reg,JSON.stringify(user));       
      
      this.singletonServ.userDetail=resp;
      
    },
    (err:any) => {
      if(err.error){
        if(err.error["errors"]){
          if(err.error["errors"][0]){
            if(err.error["errors"][0]['type']== "InvalidTokenError") {
              if(!baseSite.csAgent){
              this.profileServ.generateToken(baseSite).subscribe((token)=>{
                const tokenId = token["access_token"];
                user['token']=tokenId;
                this.setBrowserSessionData(baseSite.reg,JSON.stringify(user));
                this.getUserData(token["access_token"], id);
             });
            }else{
              this.singletonServ.sendMessage({invalidAgentToken:true});
            }
            }
          }
          }
         }
    }
  );
}
async gtmMentionMe(purchaseDetails:any){
  let _userDetail:any;
  window.dataLayer = window.dataLayer || [];
  const baseSite = this.singletonServ.catalogVersion;
  let browserData = this.getBrowserSessionData(baseSite.reg);
  if(browserData){
    const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
    const email = user.email;
    this.getUserData(user.token,email);
    _userDetail=this.singletonServ.userDetail;
  }
  if(_userDetail && _userDetail.annexcloudOptIn==true){
  const _obj={
      'event': 'eec.Mention-Me',
      'mmFirstName':purchaseDetails.deliveryAddress.firstName,
      'mmLastName':purchaseDetails.deliveryAddress.lastName,
      'mmEmail': this.retreiveUser(purchaseDetails.user.uid),
      'mmOrderTotal': purchaseDetails.totalPriceWithTax.value,
      'mmOrderId':purchaseDetails.code,     
      'mmOrderCurrency': purchaseDetails.totalPriceWithTax.currencyIso,
      'mmSituation': 'postpurchase',
      'mmSegment':'LoyaltyMember'
  }  
  window.dataLayer.push(_obj);    
}else{
  const _obj={
    'event': 'eec.Mention-Me',
    'mmFirstName':purchaseDetails.deliveryAddress.firstName,
    'mmLastName':purchaseDetails.deliveryAddress.lastName,
    'mmEmail': this.retreiveUser(purchaseDetails.user.uid),
    'mmOrderTotal': purchaseDetails.totalPriceWithTax.value,
    'mmOrderId':purchaseDetails.code,     
    'mmOrderCurrency': purchaseDetails.totalPriceWithTax.currencyIso,
    'mmSituation': 'postpurchase',
    'mmSegment':'NotLoyaltyMember'
}  
window.dataLayer.push(_obj); 
}
}
getBrowserSessionData(key){
  if (isPlatformBrowser(this.platformId)) {
    return this.singletonServ.getStoreData(key);
  }
}
setBrowserSessionData(key,data){
  if (isPlatformBrowser(this.platformId)) {
    this.singletonServ.setStoreData(key,data);
  }
}
retreiveUser(uid){
  const _user=uid.split('|');
   const _email =_user[_user.length-1];
   return _email;
 }
 gtmOriginalLocation(catalogVersion:any){
  window.dataLayer = window.dataLayer || [];
  const _dataLayer={
    'event': 'eec.originalLocation',
    'originalLocation': this.dom.location.protocol + '//' +this.dom.location.hostname + this.dom.location.pathname +this.dom.location.search
  }
  window.dataLayer.push(_dataLayer);
}
gtmGiftBuilderEvent(giftBuilderCategory,productAdded)
{
    window.dataLayer = window.dataLayer || [];
     window.dataLayer.push({
    'event': 'eec.giftBuilder',
    'eventCategory': 'Gift Builder',                      
    'eventAction': giftBuilderCategory,
    'eventLabel': productAdded
    });
}
gtmGiftBuilderStep(stepName)
{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event': 'eec.giftBuilderStep',
    'virtualPage': stepName                  
    });
}

  normalizeString(getString: string) {
    return getString?.trim().toLowerCase();
  }

  // GA4 Enhanced Conversions | MBU-261 [..]
  async ga4UserData(deliveryAddress: any) {
    const hashedEmail = await this.digestMessage(this.normalizeString(deliveryAddress?.email) ?? "");
    const hashedPhone = await this.digestMessage(this.normalizeString(deliveryAddress?.phone ?? ""));
    const hashedFirstName = await this.digestMessage(this.normalizeString(deliveryAddress?.firstName ?? ""))
    const hashedLastName = await this.digestMessage(this.normalizeString(deliveryAddress?.lastName ?? ""))
    const hashedStreet = await this.digestMessage(this.normalizeString(deliveryAddress?.line1 ?? ""))
    const hashedCity = await this.digestMessage(this.normalizeString(deliveryAddress?.town ?? ""))
    const hashedRegion = await this.digestMessage(this.normalizeString(deliveryAddress?.district ?? ""))
    const hashedPostalCode = await this.digestMessage(this.normalizeString(deliveryAddress?.postalCode ?? ""))
    const hashedCountry = await this.digestMessage(this.normalizeString(deliveryAddress?.country?.isocode ?? ""))
    
    const tempPayload = {
      "event": "user_data",
      "user_provided_data": {
        "email_address": hashedEmail ?? "",
        "phone_number": hashedPhone ?? "",
        "address": {
          "first_name": hashedFirstName ?? "",
          "last_name": hashedLastName ?? "",
          "street": hashedStreet ?? "",
          "city": hashedCity ?? "",
          "region": hashedRegion ?? "",
          "postal_code": hashedPostalCode ?? "",
          "country": hashedCountry ?? ""
        }
      }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(tempPayload)
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map,catchError, distinctUntilChanged, retry } from 'rxjs/operators';
import { SERVER_PATHS, PATH } from '../app.constant';
import {environment} from '../../environments/environment';
import {InterPolateUrlService} from "../services/commons/InterPolateUrl.service";
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdyenService extends InterPolateUrlService{ 

  http: HttpClient;
  constructor(http: HttpClient) {
        super();
        this.http = http;
  }

  getAdyenConfig(baseSite,cartObj){
    let locale:string ;
    if( baseSite.isoCode == "GB" ) locale = 'en-GB';
    else if ( baseSite.isoCode == "EU" ) locale = 'en-EU';
    else if( baseSite.isoCode == "US" ) locale = 'en-US';
    else if( baseSite.isoCode == "DE" ) locale = 'de-DE';

    return {
      locale : locale,
      environment: environment.adyenKeys.environment,
      clientKey: environment.adyenKeys.clientKey,
      amount: {
        value: Math.round(cartObj.totalPriceWithTax.value*100),
        currency: cartObj.deliveryCost.currencyIso
      },      
    }
   }
   getAdyenConfigAccount(baseSite){
    let locale:string ;
    if( baseSite.isoCode == "GB" ) locale = 'en-GB';
    else if ( baseSite.isoCode == "EU" ) locale = 'en-EU';
    else if( baseSite.isoCode == "US" ) locale = 'en-US';
    else if( baseSite.isoCode == "DE" ) locale = 'de-DE';

    return {
      locale : locale,
      environment: environment.adyenKeys.environment,
      clientKey: environment.adyenKeys.clientKey            
    }
   }

  getPaymentMethods(baseSite,token,userID,cartID){
    let url=this.interpolateUrl( environment.API_PATH +  PATH.ADYEN_PAYMENT_METHODS,{
      userId:userID,
      cartId:cartID,
        baseSite:baseSite.catalogversionId
    });
    // if(baseSite.csAgent){
    //     url=url+ "&access_token=" + baseSite.agentToken
    // }
    let httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'bearer '+token
        })
    };
    return this.http
        .get<any[]>(url,httpOptions).pipe(map(data => data,
            catchError(err => of(err.message))
        ),retry(4));
}

additionalPaymentDetails(baseSite,token,userID,cartID,request_body,splitPayment?,subscriptionId?, cardSave?){
  let url=this.interpolateUrl( environment.API_PATH +  PATH.ADYEN_PAYMENT_DETAILS,{
    userId:userID,
    cartId:cartID,
    baseSite:baseSite.catalogversionId
  });
  if (splitPayment && splitPayment.GivexCardNumber){ // set Giftcard details in param for split payment flow with 3DS
    url=url+"?givexCardNumber="+ splitPayment.GivexCardNumber.trim()+"&givexPinNumber="+splitPayment.GivexPinNumber.trim()+"&amount="+splitPayment.Amount.trim()
  }
  // if(baseSite.csAgent){
  //     url=url+ "&access_token=" + baseSite.agentToken
  // }
  if(cardSave && subscriptionId){
    if (splitPayment && splitPayment.GivexCardNumber){
      url = url+ "&isCardSavedForNextPayment=true&subscriptionId="+subscriptionId;
    } else
      url = url+ "?isCardSavedForNextPayment=true&subscriptionId="+subscriptionId;
    }
  let httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'bearer '+token
      })
  };
  return this.http
      .post<any[]>(url,request_body,httpOptions).pipe(map(data => data,
          catchError(err => of(err.message))
      ));
}
additionalPaymentDetailsThreeDs(baseSite,token,userID,cartID,subscriptionId,request_body,splitPayment?){
  let url=this.interpolateUrl( environment.API_PATH +  PATH.ADYEN_PAYMENT_DETAILS_THREEDS,{
    userId:userID,
    cartId:cartID,
    baseSite:baseSite.catalogversionId,
    subscriptionId:subscriptionId
  });
  // if (splitPayment && splitPayment.GivexCardNumber){ // set Giftcard details in param for split payment flow with 3DS
  //   url=url+"&givexCardNumber="+ splitPayment.GivexCardNumber.trim()+"&givexPinNumber="+splitPayment.GivexPinNumber.trim()+"&amount="+splitPayment.Amount.trim()
  // }
  // if(baseSite.csAgent){
  //     url=url+ "&access_token=" + baseSite.agentToken
  // }
  let httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'bearer '+token
      })
  };
  return this.http
      .post<any[]>(url,request_body,httpOptions).pipe(map(data => data,
          catchError(err => of(err.message))
      ));
}

}

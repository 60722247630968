import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule,APP_INITIALIZER } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha-18';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import { DeviceDetectorModule } from "ngx-device-detector";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterModule } from "./component/footer/footer.module";
import { productviewComponentService } from "./component/productview/productview.service";
import { AppService } from "./app.service";
import { HeaderComponentService } from "./component/header/header.service";
import { SingletonService } from "./services/singleton.service";
import { PagerService } from "./services/pager.service";
import { BreadcrumbComponent } from "./component/breadcrumb/breadcrumb.component";
import { BasketPageComponentService } from "./checkoutpage/basketpage/basketpage.service";
import {HeaderModule} from './component/header/header.module';
import { profileComponentService } from "./component/profile-form/profile.service";
import { OrderHistoryService } from "./component/orders/orders.service";
import { OrderPipe } from "./pipe/order.pipe";
import { GiftCardComponentService } from "./gift-cards/gift-cards.service";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AuthenticationGuardService } from "./guards/authentication-guard.service";
import { RoleGuardService } from "./guards/role-guard.service";
import { AuthenticationService } from "./services/authentication.service";
import { LoaderComponent } from "./loader/loader/loader.component";
import { StorefinderService } from "./storefinder/storefinder.service";
import { PlacePredictionService } from "./services/postcode-prediction.service";
import { PaymentService } from "./component/payment-detail/payment.service";
import {NewsLetterComponentService} from './newsletter-signup/newsletter.service';
import {CategoryComponentService} from './categorylanding-page/categorylanding-page.service';
import {CustomerAccountService} from './component/customer-account/customer-account.service';
import { TranslateService } from './translate.service';
import { TranslatePipe } from './translate.pipe';
import { CsHeaderComponent } from './component/cs-header/cs-header.component';
import {CSCustomerService} from './component/cs-header/cs-header.service';
import { CustomValidatorDirective } from './forms/custom-validator.directive';
import { CustomDirectiveFocusDirective } from './forms/custom-directive-focus.directive';
import {AmpHomePageService} from './amp-homepage/amp-homepage.service';
import { ConfigService } from './services/config.service';
import {MetaService} from "./services/meta.service";
import {StoreComponent} from './store/store.component';
import {FooterComponentService} from './component/footer/footer.service';
import { BrowseComponent } from './browse/browse.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AccordianModule} from "./accordion/accordian.module";
import {AccordianItemModule} from "./accordion-item/accordion-item.module";
import {GtmMethodService} from "./services/gtmmethods.service";
import {LinkService} from "./services/link.service";
import { CookiebarComponent } from './cookiebar/cookiebar.component';
import { CookieService } from 'ngx-cookie-service';
import { FFCatalogService } from './ff-landingpage/ff-landingpage.service';
import { FragranceComponent } from './fragrance/fragrance.component';
import {FragranceService} from "./fragrance/fragrance.service";
import { SEOService } from "./services/seo.service";
import { FfLandingpageComponent } from './ff-landingpage/ff-landingpage.component';
import{StoreSpecificRoleGuardService} from "./guards/store-specific-guard.service";
import { ResizeService } from './services/resize.service';
import {EURedirectService} from "./services/enivronment-eu.service";
import { GBRedirectService } from "./services/enviroment-gb.service";
import { USRedirectService} from "./services/usRedirect.service";
import {PreloadService} from "./services/pre-loadstartegy.service";
import { CovidOfferPageComponent } from './store/covid-offer-page/covid-offer-page.component';
import { RouteRedirectService } from './services/route-redirect.service';
import { CandleCareTipsComponent } from './lifestyle/candle-care-tips/candle-care-tips.component';
import { PayWithKlarnaComponent } from './content-pages/pay-with-klarna/pay-with-klarna.component';
import { BathBeautyOutletComponent } from './content-pages/bath-beauty-outlet/bath-beauty-outlet.component';
import { AboutUsComponent } from './content-pages/about-us/about-us.component';
import { ExclusiveGamesComponent } from './exclusive-games/exclusive-games.component';
import { ExclusiveGameGuard } from "./guards/exclusive-game.guard";
import { AmplienceService } from "./services/amplience.service";
import { AdyenService } from "./services/adyen.service";
import {SanitizeHtmlPipeModule} from '../app/pipe/sanitize.module';
import {MbBreadCrumbModule} from './component/mb-bread-crumb/mb-bread-crumb.module';
import {SharedWishlistComponent} from './shared-wishlist/shared-wishlist.component';
import { CollectionCategorylpModule } from './new-landingPages-2023/collections/collection-categorylp.module';
import { CollectionSubcatLpComponent } from './new-landingPages-2023/collections/collection-subcat-lp/collection-subcat-lp.component';
import { RootCategoryLpComponent } from './new-landingPages-2023/root-category/root-category-lp.component';
import { PrincesTrustComponent } from './new-landingPages-2023/princes-trust/princes-trust.component';
import { NgOptimizedImage } from '@angular/common';


export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}
export function getBaseHref(): string {
  return window.location.pathname;
} 
//Commented to fix window.location error during build serve
//const urlParams = new URLSearchParams(window.location.search);
//const language = urlParams.get('country_store');

@NgModule({ declarations: [
        AppComponent,
        BreadcrumbComponent,
        OrderPipe,
        TranslatePipe,
        LoaderComponent,
        CsHeaderComponent,
        CustomValidatorDirective,
        CustomDirectiveFocusDirective,
        BrowseComponent,
        CookiebarComponent,
        StoreComponent,
        FragranceComponent,
        FfLandingpageComponent,
        CovidOfferPageComponent,
        CandleCareTipsComponent,
        PayWithKlarnaComponent,
        BathBeautyOutletComponent,
        AboutUsComponent,
        ExclusiveGamesComponent,
        SharedWishlistComponent,
        CollectionSubcatLpComponent,
        RootCategoryLpComponent,
        PrincesTrustComponent
    ],
    bootstrap: [AppComponent], imports: [NgbModule,
        CommonModule,
        RecaptchaModule,
        BrowserModule.withServerTransition({ appId: "moltonbrown-app" }),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        FooterModule,
        AppRoutingModule,
        //DeviceDetectorModule.forRoot(),
        HeaderModule,
        AccordianModule,
        AccordianItemModule,
        SanitizeHtmlPipeModule,
        MbBreadCrumbModule,
        CollectionCategorylpModule,
        NgOptimizedImage], providers: [
        PreloadService,
        AppService,
        LinkService,
        CategoryComponentService,
        PlacePredictionService,
        CSCustomerService,
        profileComponentService,
        BasketPageComponentService,
        productviewComponentService,
        PagerService,
        HeaderComponentService,
        SingletonService,
        AuthenticationGuardService,
        RoleGuardService,
        AuthenticationService,
        GiftCardComponentService,
        OrderHistoryService,
        StorefinderService,
        PaymentService,
        NewsLetterComponentService,
        CustomerAccountService,
        FooterComponentService,
        TranslateService,
        AmpHomePageService,
        CookieService,
        GtmMethodService,
        StoreSpecificRoleGuardService,
        ConfigService,
        MetaService,
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: 'en'
        },
        { provide: '$scope', useExisting: '$rootScope' },
        SEOService,
        FFCatalogService,
        FragranceService,
        ResizeService,
        EURedirectService,
        GBRedirectService,
        USRedirectService,
        RouteRedirectService,
        ExclusiveGameGuard,
        AmplienceService,
        AdyenService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}  
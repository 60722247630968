import {
  Inject,
  Component,
  OnInit,
  HostListener,
  ViewEncapsulation,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Renderer2,
  Input,
  OnChanges,
  SimpleChange,
  PLATFORM_ID
} from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { Title } from "@angular/platform-browser";
import { HeaderComponentService } from "../header/header.service";
import { SingletonService } from "../../services/singleton.service";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderComponent } from "../header/header.component";
import { TranslateService } from "../../translate.service";
import { BasketPageComponentService } from "../../checkoutpage/basketpage/basketpage.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { productviewComponentService } from "../productview/productview.service";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { GtmMethodService } from '../../services/gtmmethods.service';
import { AmplienceService } from 'src/app/services/amplience.service';
import { DeliveryKey } from '../../amplience.constant';
import * as _ from "lodash";
import { isPlatformBrowser } from '@angular/common';
import { WebExtendService } from "src/app/services/webExtend.service";

declare var AmpCa: any;
declare var $: any;
declare var window: any;
@Component({
  selector: "app-header-submenu",
  templateUrl: "./header-submenu.component.html",
  styleUrls: ["./header-submenu.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HeaderSubmenuComponent extends HeaderComponent
  implements OnChanges, OnInit, AfterViewInit {
  isRegUser: boolean;
  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.searchFieldContstruction();
  }
  @HostListener('window:blur', ['$event'])
  onBlur(event: FocusEvent): void {
    this.searchFieldContstruction();
  }
  @HostListener('document:click')
  onBlurSearchCategory() {
    this.searchFieldContstruction();
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.clearSearch();
    if ($("#userDropdown").hasClass("show")) {
      this.toggleProfile(event);
    }
  }
  searchFieldContstruction() {
    this.searchBlock = false;
    this.searchPrdctText = "";
    this.searchResultValue = "";
    if (this.searchField) {
      if (this.searchField.nativeElement) {
        this.searchField.nativeElement.value = "";
      }
    }
    this.searchResults.length = 0;
    this.searchQuery = '';
    this.searchForm.reset();
  }
  @ViewChild("searchInput") searchField: ElementRef;
  @ViewChild("closeBtn") closeBtn: ElementRef;
  @Input() message: any;
  @Input() menuLocalData: any;
  searchPrdctText: any;
  param1: any;
  previewId:any;
  testDKey:any;
  showMegaMenu:boolean;
  searchCtgry: boolean = true;
  mouseEnter: boolean;
  searchBlock: boolean;
  noResultsearchBlock: boolean;
  searchfreetext: any;
  searchResults: Array<any>;
  searchResultValue: string;
  hoverOnSearchBlock: boolean;
  searchBlockNoResult: boolean = false;
  renderCart: boolean = true;
  openSearchInput: boolean;
  searchQuery: string;
  searchCat: boolean;
  searchForm: UntypedFormGroup;
  enableSearchicon: boolean;
  categoryResultData: any;
  totalAmount: number;
  catalogTree: any;
  isocode: string;
  countryCodeforSearch: string;
  cartTotalCount: string = "0";
  cartLoading: string = "false";
  cartBadge: boolean;
  siteHeaderLogo: any;
  mbCategories: Array<any>;
  constructor(
    @Inject(DOCUMENT) public dom,
    @Inject(PLATFORM_ID) public platformId: Object,
    public renderer: Renderer2,
    public el: ElementRef,
    public headerServ: HeaderComponentService,
    public singletonServ: SingletonService,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public basketServ: BasketPageComponentService,
    public deviceService: DeviceDetectorService,
    public quickServ: productviewComponentService,
    public fb: UntypedFormBuilder,
    public gtmServe: GtmMethodService,
    public ampService: AmplienceService,
    public webExtend:WebExtendService
    
  ) {
    super(dom, platformId, headerServ, singletonServ, location, router, route, basketServ, quickServ, gtmServe, ampService, webExtend);
    this.searchResults = [];
    this.searchResultValue = "";
    this.searchBlock = false;
    this.openSearchInput = false;
    this.searchForm = this.fb.group({ search: new UntypedFormControl('') });
    this.route.queryParams.subscribe(params => {
      this.param1 = params['api'];
      this.previewId = params['previewId'];
      this.testDKey = params['testDKey'];    
    });
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes["message"]) {
      if (changes["message"]["currentValue"] != undefined) {
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        if (isMobile || isTablet) {
          this.message = changes["message"]["currentValue"];
        }
      }
    }
    }
    
  ngOnInit() {
    const baseSite = this.singletonServ.catalogVersion;
    
    if(this.previewId && this.testDKey){
      if(this.testDKey == 'MegaMenu'){
        this.showMegaMenu = true;
      }
    }


    // this.ampService.getContentByKey( DeliveryKey.headerLogo[baseSite.isoCode]).subscribe(resp=>{    
    //   this.siteHeaderLogo = resp["content"]["logo"]; 
    // });
   }
  ngAfterViewInit() {
    this.singletonServ.getMessage().subscribe(message => {
      if (message.moltonSideumenu) {
        this.sidemenuStatus = message.moltonSideumenu.state;
      } else if (message.searchResults) {
        this.searchResultValue = message.searchText;
        this.onSearchResults();
      } else if (message.pointAsAsagent) {
        this.renderCatalogData();
      } else if (message.signOutCart){
        this.cartTotalCount = "0";
      }

      if(message && message.cartLoading ) {
        this.cartLoading = message.cartLoading;
        if(message.cartTotalItems && message.cartLoading == "false"  ) {
          this.cartBadge = true;
          this.cartTotalCount = message.cartTotalItems;
        }
      }
    });
    this.renderCatalogData();
    setTimeout(() => {
      // Using the class to select the search input
      const searchFieldHidden = this.el.nativeElement.querySelector('[name="search_term_string"]');
      if (searchFieldHidden) {
        this.renderer.setAttribute(searchFieldHidden, 'aria-label', 'Search');
      }
    }, 1000); // Adjust the delay as necessary
  }

  mouseLeave() {
    this.mouseEnter = false;
  }
  goToHome(event) {
    const baseSite = this.singletonServ.catalogVersion;
    if ((event.ctrlKey || event.metaKey) && event.which === 1) {
      event.stopPropagation();
      event.preventDefault();
      if (this.getBrowserSessionData(baseSite.csAssistant) || baseSite.csAgent) {
        window.open("/store/index?ASM=true");
      } else {
        window.open("/store/index");
      }
    } else {
      if (this.getBrowserSessionData(baseSite.csAssistant) || baseSite.csAgent) {
        this.router.navigate(["store", "index"], {
          queryParams: { ASM: true },
          queryParamsHandling: "merge"
        });
      } else {
        this.router.navigate(["/store/index"]);
      }
    }
  }

  onLeaveSearchBox() {
    this.hoverOnSearchBlock = false;
  }
  onFocusSearch(event) {
    this.renderer.selectRootElement(this.searchField.nativeElement). focus();
    this.searchField.nativeElement.focus();
  }
  onsearchClicked() {
    const val = this.searchForm.value;
    if (val.search) {
      if (val.search.length >= 3) {
        const deocdeVal = encodeURIComponent(val.search);
        this.searchResults.length = 0;
        this.searchCtgry = false;
        this.searchBlock = false;
        this.searchBlockNoResult = false;
        this.searchPrdctText = "";
        this.searchResultValue = "";
        this.searchQuery = '';
        this.searchfreetext = '';
        this.searchField.nativeElement.value = "";
        this.searchForm.reset();
        if (this.categoryResultData) {
          if (this.categoryResultData.keywordRedirectUrl) {
            if (this.categoryResultData.keywordRedirectUrl != '') {
              const _keywordRedirectUrl = this.categoryResultData.keywordRedirectUrl;

              if (_keywordRedirectUrl.indexOf("/c/") != -1) {
                const _replaceUrl = _keywordRedirectUrl.replace("/c/", "/");
                const _url = "/store" + _replaceUrl;
                this.router.navigate([_url]);
              } else if (_keywordRedirectUrl.indexOf("/p/") != -1) {
                const _replaceUrl = _keywordRedirectUrl.replace("/p/", "/");
                const _url = "/store" + _replaceUrl;
                this.router.navigate([_url]);
              }

            } else {
              this.router.navigate(["store", "browse", deocdeVal]);
            }
          } else {
            this.router.navigate(["store", "browse", deocdeVal]);
          }
        } else {
          this.router.navigate(["store", "browse", deocdeVal]);
        }
      } else {
        this.searchCat = true;
        this.renderer.selectRootElement(this.searchField.nativeElement). focus();
        this.searchCtgry = true;
        if (!this.openSearchInput) {
          this.openSearchInput = true;
        }
      }
    } else {
      this.searchCat = true;
      this.renderer.selectRootElement(this.searchField.nativeElement). focus();
      this.searchCtgry = true;
      if (!this.openSearchInput) {
        this.openSearchInput = true;
      }
    }
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      event.target.blur();
      const searchForm = this.searchForm.value;
      if (searchForm.search) {
        if (searchForm.search.length >= 3) {
          this.searchResults.length = 0;
          this.onSubmitForm(event);
        }
      }
      return false;
    }
  }
  onSubmitForm(event) {
    this.removeBrowserSessionData("searchId");
    const searchForm = this.searchForm.value;
    const val = searchForm.search;
    if (val) {
      const deocdeVal = encodeURIComponent(val);
      deocdeVal.trim();
      if (deocdeVal.length != 0) {
        this.searchForm.reset();
        this.searchResults.length = 0;
        this.searchCtgry = false;
        this.searchBlock = false;
        this.searchPrdctText = "";
        this.searchResultValue = "";
        this.searchQuery = '';
        this.searchField.nativeElement.value = "";
        this.enableSearchicon = false;
        if (this.categoryResultData) {
          if (this.categoryResultData.keywordRedirectUrl) {
            if (this.categoryResultData.keywordRedirectUrl != '') {
              const _keywordRedirectUrl = this.categoryResultData.keywordRedirectUrl;

              if (_keywordRedirectUrl.indexOf("/c/") != -1) {
                const _replaceUrl = _keywordRedirectUrl.replace("/c/", "/");
                const _url = "/store" + _replaceUrl;
                this.router.navigate([_url]);
              } else if (_keywordRedirectUrl.indexOf("/p/") != -1) {
                const _replaceUrl = _keywordRedirectUrl.replace("/p/", "/");
                const _url = "/store" + _replaceUrl;
                this.router.navigate([_url]);
              }

            } else {
              this.router.navigate(["store", "browse", deocdeVal]);
            }
          } else {
            this.router.navigate(["store", "browse", deocdeVal]);
          }
        } else {
          this.router.navigate(["store", "browse", deocdeVal]);
        }

      } else {
        this.searchCtgry = true;
      }
    } else {
      this.searchCtgry = true;
    }
    this.closeBtn.nativeElement.click();
  }
  onSearcKeyUp(event) {
    this.searchForm.controls['search'].patchValue(event.target.value);
    const val = this.searchForm.value;
    if (val.search) {
      this.enableSearchicon = (val.search.length >= 3) ? true : false;
    }
    this.onSearchChange(event, val.search)
  }
  onSearchChange(event, searchValue: string) {
    this.searchCtgry = true;
    const baseSite = this.singletonServ.catalogVersion;
    if (searchValue) {
      const val = searchValue.trim();
      this.searchResultValue = val;
      this.openSearchInput = true;
      this.searchQuery = val;
      if (event.keyCode == 13) {
        this.searchResults.length = 0;
      } else {
        if (val) {
          if (val.length >= 3) {
            const deocdeVal = encodeURIComponent(val);
            this.headerServ
              .getCategorySearchResults(baseSite, deocdeVal)
              .subscribe((resp: any) => {
                if (resp) {
                  this.categoryResultData = resp;
                  this.searchfreetext = '';
                  this.countryCodeforSearch = baseSite.isoCode;
                  if (resp["products"]) {
                    this.searchfreetext = resp["freeTextSearch"];
                    if (resp["products"].length > 0) {
                      this.searchResults = resp["products"].slice(0, 3);
                      this.searchCtgry = true;
                      this.searchBlock = true;
                      this.searchBlockNoResult = false;
                    }
                    else {
                      this.searchResults = [];
                      this.searchCtgry = true;
                      this.searchBlock = false;
                      this.searchBlockNoResult = true;

                    }
                  } else {
                    this.searchResults = [];
                    this.searchCtgry = true;
                    this.searchBlock = false;
                    this.searchBlockNoResult = true;
                  }
                }
              },
                err => {
                  this.searchBlock = false;
                  this.searchBlockNoResult = false;
                }
              );
          } else {
            this.searchResults = [];
            this.searchCtgry = true;
            this.searchBlock = false;
            this.searchBlockNoResult = false;
          }
        } else {
          this.searchResults = [];
          this.searchCtgry = true;
          this.searchBlock = false;
          this.searchBlockNoResult = false;
        }
      }
    }
  }
  showSearchBox() {
    this.searchBlock = true;
    this.hoverOnSearchBlock = true;
    this.searchCtgry = true;
  }

  onSearchProduct(event, searchItem) {
    event.stopPropagation();
    event.preventDefault();
    const catgIndex = searchItem.url.indexOf("/c/");
    const prdctIndex = searchItem.url.indexOf("/p/");
    if (catgIndex != -1) {
      const url = "/store" + searchItem.url.replace("/c/", "/");
      this.searchResults = [];
      this.searchForm.reset();
      this.searchBlock = false;
      this.noResultsearchBlock = false;
      this.searchCtgry = false;
      this.searchPrdctText = "";
      this.searchResultValue = "";
      if (event.ctrlKey && event.which === 1) {
        window.open(url);
      } else {
        this.router.navigate([url]);
      }

    } else if (prdctIndex != -1) {
      this.searchForm.reset();
      const url = "/store" + searchItem.url.replace("/p/", "/");
      this.searchResults = [];
      this.searchBlock = false;
      this.noResultsearchBlock = false;
      this.searchCtgry = false;
      this.searchPrdctText = "";
      this.searchResultValue = "";
      if (event.ctrlKey && event.which === 1) {
        window.open(url);
      } else {
        this.router.navigate([url]);
      }
    }
    this.closeBtn.nativeElement.click();
  }
  clearSearch(){
    this.searchResults = []
    this.searchForm.controls['search'].setValue('');
  }
  onSearchResults() {
    const search = (this.searchResultValue) ? this.searchResultValue : this.searchQuery;
    this.searchCtgry = false;
    this.searchBlock = false;
    this.searchPrdctText = "";
    this.searchResultValue = "";
    this.searchQuery = "";
    this.searchResults = [];
    this.removeBrowserSessionData("searchId");
    const val = search.trim();
    const deocdeVal = encodeURIComponent(val);

    if (deocdeVal.length != 0) {
      this.searchCtgry = false;
      this.searchBlock = false;
      this.searchPrdctText = "";
      this.searchResultValue = "";
      this.searchResults = [];
      this.searchQuery = '';
      setTimeout(() => {
        this.searchField.nativeElement.blur();
      })
      this.router.navigate(["store", "browse", deocdeVal]);
    }
    this.closeBtn.nativeElement.click();
  }
  onViewBasket(event) {
    event.stopPropagation();
    this.router.navigate(["store", "mbcart", "mbBasket"]);
  }
  setFocus(): void{
    document.getElementById("product_channel").focus();
  }
  getCartCount() {
    let sum = 0;
    this.totalAmount = 0;
    if (this.singletonServ.cartObj) {
      if (this.singletonServ.cartObj.totalItems != 0) {
        for (let i = 0; i < this.singletonServ.cartObj["entries"].length; i++) {
          if (this.singletonServ.cartObj["entries"][i]['pickAgain']) {
            sum += this.singletonServ.cartObj["entries"][i]["quantity"];
          } else {
            if (!this.singletonServ.cartObj["entries"][i]["product"]["isSample"]) {
              sum += this.singletonServ.cartObj["entries"][i]["quantity"];
            }
          }
        }
      }
    }
    return sum;
  }

  discardSubscription(event) {
    event.preventDefault();
    this.subscription.unsubscribe();
  }
  toggleMenu(event) {
    event.preventDefault();
    this.onSidemenutap();
  }
  swipMenu(event) {
    this.onSidemenutap();
  }
  getSearchRouterPath(item) {
    return "/store" + item.url.replace("/p/", "/");
  }
  renderCatalogData() {
    const baseSite = this.singletonServ.catalogVersion;
    if (baseSite) {
      this.singletonServ.getCatalogData().subscribe(resp => {
        const catalogData = resp["catalogs"][0]["catalogVersions"];
        const menuId = _.findIndex(catalogData, (o: any) => {
          return o.id == "Online";
        });
        let menuData = catalogData[menuId].categories;
        // menuData.sort(function (a, b) {
        //   return a.order - b.order;
        // });
        menuData = _.sortBy(menuData, 'order');
        const ctlog = _.clone(menuData);
        const _copy = this.nestedCopy(menuData);
        this.singletonServ.pickPackCategories = this.nestedCopy(menuData);
        this.constructCatalog(_copy);
        this.constructAmpContent(ctlog);
      },
        error => { }
      );
    }
  }

  constructAmpContent(catalog) {
    const that = this;
    catalog.map((obj, k) => {
      if (obj.categoryDisplayName == 'Editorial') {
        obj["ampContent"] = [];
      }
      if (obj["subcategories"]) {
        catalog[k]["subcategories"].sort(function (a, b) {
          return a.order - b.order;
        });
      }
      if (obj.subcategories) {
        if (
          obj.subcategories[10] &&
          !obj.subcategories[10]["parent"]
        ) {
          if (obj.subcategories.length > 11) {
            obj.subcategories.splice(10, 0, { name: "Hidden Category", categoryDisplayName: '' });
            obj["subcategories"] = that.chunkArray(obj.subcategories, 11);
          } else {
            obj["subcategories"] = that.chunkArray(obj.subcategories, 11);
          }
        } else if (
          obj.subcategories[21] &&
          !obj.subcategories[21]["parent"] &&
          !obj.subcategories[21]["description"]
        ) {
          if (obj.subcategories.length > 21) {
            obj.subcategories.splice(20, 0, { name: "Hidden Category" });
            obj["subcategories"] = that.chunkArray(obj.subcategories, 11);
          } else {
            obj["subcategories"] = that.chunkArray(obj.subcategories, 11);
          }
        } else {
          obj["subcategories"] = that.chunkArray(obj.subcategories, 11);
        }
      }
    });
    that.mbCategories = catalog;
    this.singletonServ.catalogmenu = catalog;
  }
  constructStaticCard() {
    const baseSite = this.singletonServ.catalogVersion;
    this.singletonServ.catalogmenu.map((obj, k) => {
      let api;
      if (this.param1) {
        api = "https://" + this.param1;
      }
      else {
        api = "https://c1.amplience.net"
      }
      if (obj.bannerId && obj.order != 100) {
        obj["subcategories"].push({ content: [] });
        const objId = obj["subcategories"].findIndex(function (obj) {
          return !Array.isArray(obj);
        });
        let bannerBox = obj.bannerId.split(",");
        _.forEach(bannerBox, function (value, i) {
          value.replace("/ /g", "");
          if (value.length != 2) {
            if (AmpCa) {
              if (AmpCa.utils) {


                if (AmpCa.utils.getHtmlServiceData) {
                  AmpCa.utils.getHtmlServiceData({
                    auth: {
                      baseUrl: api,
                      id: value.trim(),
                      store: "moltonbrown",
                      templateName: "slot-contentWrapper",
                      locale: baseSite.locale
                    },
                    callback: function (data) {
                      const temp = {
                        templatename: data
                      };
                      if (obj.categoryDisplayName != 'Editorial') {
                        obj["subcategories"][objId]["content"].push(temp);
                      } else {
                        obj['ampContent'].push(temp);
                      }
                    }
                  });
                }
              }
            }
          }
        });
      }
    });
  }
  constructCatalog(category) {
    category.map((obj, k) => {
      if (obj["subcategories"]) {
        category[k]["subcategories"].sort(function (a, b) {
          return a.order - b.order;
        });
      }
    });
    const catalogTree = this.nestedCopy(category);
    const copyCatlogtree = this.nestedCopy(category);
    catalogTree.map((obj: any, i) => {
      if (obj.categoryDisplayName === 'Editorial') {
        obj.specialCategory = true;
      } else if (obj.categoryDisplayName === 'Sale') {
        obj.specialCategory = false;
      }
      else if (obj.categoryDisplayName === 'Sustainability' || obj.categoryDisplayName === 'Nachhaltigkeit') {
        obj.specialCategory = true;
      }
      if (obj.subcategories) {
        const root = obj.subcategories.sort((a, b) => {
          return a.order - b.order;
        });
        if (root) {
          root.map((item) => {
            const _obj = _.filter(copyCatlogtree[i]["subcategories"], (o) => {
              if (o.parent) {
                return item.id == o.parent;
              }
            });
            if (_obj.length != 0) {
              item["subcategories"] = _obj;
              _obj.map(() => {
                const _objIndx = _.findIndex(catalogTree[i]["subcategories"], (o: any) => {
                  return item.id == o.parent;
                }
                );
                catalogTree[i]["subcategories"].splice(_objIndx, 1);
              });
            }
          });
        }
      }
    });

    this.catalogTree = catalogTree;
    this.singletonServ.menudata = this.catalogTree;
    const menuInfo = { catgories: catalogTree };
    this.singletonServ.sendMessage(menuInfo);
  }
  chunkArray(myArray, chunk_size) {
    let results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }
  nestedCopy(array) {
    return JSON.parse(JSON.stringify(array));
  }
  
  toggleProfile(event) {
    event.preventDefault();
    event.stopPropagation();
    $("#userDropdown").toggleClass("show");
  }
  onAccountClick(event) {
    this.toggleProfile(event);
    const baseSite = this.singletonServ.catalogVersion;
    if (!this.getBrowserSessionData(baseSite.reg)) {
      this.singletonServ.loggedIn = false;
      this.loggedIn = false;
      this.router.navigate(["store", "myacc", "mbLogin"]);
    }
  }
  onProfile(event) {
    this.toggleProfile(event);
    if((event.ctrlKey && event.which === 1) || event.metaKey){
      window.open('/store/myaccount');
      }else{
        this.router.navigate(["store", "myaccount"]);
      }
  }
  onAccountSignOut(event) {
    this.toggleProfile(event);
    window.location.reload();
    this.router.navigate(["store", "myacc", "mbLogin"]);
    const that = this;
    this.loggedIn = false;
    this.singletonServ.loggedIn = false;
    if (isPlatformBrowser(this.platformId)) {
      this.removeBrowserSessionData("order");
      const _sessionNames = Object.keys(localStorage);
      if (_sessionNames.length) {
        _sessionNames.map((obj) => {
          if (obj != 'prefered_lng') {
            that.removeBrowserSessionData(obj);
          }
        });
      }
    }
    this.singletonServ.sendMessage({ signOutCart: true });
    this.singletonServ.sampleIndex = '';
    //this.cartCo.signoutCredentials();
    this.setCookie('og_auth', '', 0);
    window.location.reload();
  }
  setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; secure=true; path=/; SameSite=None";
  }
  OnIconLinkClick(event,url){
    event.preventDefault();
    event.stopPropagation();
    if((event.ctrlKey && event.which === 1) || event.metaKey){        
    window.open(url);
    }else{
        this.router.navigate([url]);
    }
  }

 isLoggedInUser(){
   const baseSite = this.singletonServ.catalogVersion;
    if(!this.getBrowserSessionData(baseSite.reg)){
       this.isRegUser = false; 
       this.singletonServ.sendMessage({"isRegUser": this.isRegUser})
      }
    if (this.getBrowserSessionData(baseSite.reg)) {  
        this.isRegUser = true; 
        this.singletonServ.sendMessage({"isRegUser": this.isRegUser}) 
        } 
      }
}

import { Inject,Injectable, NgZone,PLATFORM_ID, RendererFactory2, ViewEncapsulation, inject  } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { UntypedFormGroup } from "@angular/forms";
import { DOCUMENT } from '@angular/common';
import { Observable, ReplaySubject, Subject } from "rxjs";
import { Router } from "@angular/router";
import { SCREEN_SIZE } from './screen-size.enum';
import { environment }     from '../../environments/environment';
import * as _ from "lodash";
import { CatalogData } from "src/models/user.model";
declare var window: any;
import { InjectionToken } from '@angular/core';
 
export const WINDOW = new InjectionToken<Window>('WindowToken', {
  factory: () => {
    if(typeof window !== 'undefined') {
      return window
    }
    return new Window();
  }
});
declare var $:any;
@Injectable()
export class SingletonService {
  window:any = inject(WINDOW);
  environment:string=(environment&&environment.environmentName=='production')?'production':'develop';
  breadcrumbcheck:boolean;
  frScript:string;   
  basketTab:boolean;
  sampleTab:boolean;
  checkOutTab:boolean;
  catalogmenu:Array<any>;
  translateData:any;
  frSlotIdList:any;
  ampContentId:any;
  welcomeTab:boolean;
  wishListCreated:boolean;
  shippingTab:boolean;
  confirmationtab:boolean;  
  private subject = new Subject<any>();
  creditCardFlag = new Subject<boolean>();
  saveCardFlag = new Subject<any>();
  paymentOptionsFlag = new Subject<any>();

  sessionStarts:boolean;
  menudata: Array<any>;
  mbStores:Array<any>;
  gfsData:any;
  loggedIn: boolean ;
  cartCount: number;
  checkoutStatus: boolean;
  totalAmount: any;
  googleScriptKey:string;
  catalogVersionId: string = "moltonbrown-gb";
  catalogVersion: any;
  cartObj: any;
  userDetail:any;
  confirmOrderObj: any;
  giftObj: any;
  favourites: Array<any>;
  cartStatus: boolean;
  storeDetail: any;
  pickPackCategories:any;
  footerLinks:any;
  homepage:any;
  cardList:Array<any>;
  cardData:any;
  myMessage = new Subject<string>();
  appLocaleData:any;
  portalStoreName:string;
  redirectUrl:string;
  captchaCode:any;
  interval:any;
  facets:Array<any>;
  sampleIndex: any;
  giftWithcardForm:UntypedFormGroup;
  giftcardForm:UntypedFormGroup;
  giftBoxWithMessageForm:UntypedFormGroup;
  isGTMStep2:boolean;
  threeDsCardsavePay:boolean =false;
  threeDsgiftCardsavePay:boolean =false;
  loggedInPageLoad:boolean = false;
  private catalogData = new ReplaySubject<CatalogData>();
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs',
      css: `d-block d-sm-none`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm',
      css: `d-none d-sm-block d-md-none`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md',
      css: `d-none d-md-block d-lg-none`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg',
      css: `d-none d-lg-block d-xl-none`
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl',
      css: `d-none d-xl-block`
    },
  ];
  creditCardDetails:UntypedFormGroup;
  adyenCreditCardDetails: any;
  adyenAddNewCardDetails: any;
  threeDsComponent: any;
  adyenCreditCardendDigits: any;
  adyenCheckoutBrand: any;
  cardType: any;
  isSaveCardSelected: boolean;
  encryptedSecurityCode: any;
  isAdyenExpressCheckout: boolean;
  setAsDefaultPaymentEdit : string = '';
  setAsSubscriptionPaymentEdit: string = '';
  threeDSData: any;
  threeDSSubscriptionId: any;
  adyenGiftCard: any;
  paypalPaymentData: any;
  constructor(
     @Inject(PLATFORM_ID) public platformId: Object,   
     @Inject(DOCUMENT) public dom,
     public zone: NgZone,
     public router: Router,
     private rendererFactory: RendererFactory2,
     private cookieService: CookieService
     ) {
    //  window.angularComponentRef = {
    //   zone: this.zone,
    //   componentFn: value => this.updateCart(value),
    //   component: this
    // };
  }
  openInNextPage(url){
    // this.window.open(url);
  }
  createCaptcha(id){
    this.dom.getElementById(id).innerHTML = "";
    var charsArray ="6LcNKZ0UAAAAAKbLLIOM2xNQ6pfgURd_0GlBUgIZ";
    var lengthOtp = 6;
    var captcha = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Charthis.acters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next charthis.acter from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = this.dom.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    this.captchaCode=captcha.join("");
    this.dom.getElementById(id).appendChild(canv);
  }
  setCrossDomainCookie(cname,cvalue,query) {
    if (isPlatformBrowser(this.platformId)) {
    this.cookieService.set(cname,cvalue,24,'/',query);
    }
  }
  getCookie(name) {
    if (isPlatformBrowser(this.platformId)) {
    return decodeURIComponent(this.dom.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  }
}
  
   setCookie(name, value) {
    const date=new Date();
    date.setTime(date.getTime()+(180*24*60*60*1000));
    document.cookie=encodeURIComponent(name)+"="+escape(value)+
    "; expires="+date.toUTCString()+"; path=/;domain="+window.location.host+ ";secure;SameSite=Lax";
  }


  getCrossDomainCookie(cname) {
    const name = cname + "=";
    const ca = this.dom.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  clearItem() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }

  removeItem(name) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(name);
    }
  }
  setStoreData(name,obj) {
    if (isPlatformBrowser(this.platformId)) {
       localStorage.setItem(name,obj);
    }
  }
  clearToken(name){
    if (isPlatformBrowser(this.platformId)) {
     if(localStorage.getItem(name)){
       if(this.catalogVersion['guest'] == name){
         localStorage.removeItem(name); 
           window.location.href="/store/global/sessionExpired";
       } else{
        if(this.catalogVersion['reg'] == name){
          const _user= JSON.parse(localStorage.getItem(name)); 
          delete _user.token;
          localStorage.setItem(name,JSON.stringify(_user));
            window.location.href="/store/global/sessionExpired";
        }
       }
     }
    }
  }
  scrollToTarget(id){
    if($(id)){
      $('html,body').animate({
       scrollTop: $(id).offset().top
      },{
       duration: 'slow',
       easing: 'swing'
      });
   }
  }
  scrollToHashTarget(){
    if (isPlatformBrowser(this.platformId)) {
    if (window.location.hash) {
        const hash = window.location.hash;  
        if ($(hash).length) {
          if($(hash)){
            $('html,body').animate({
             scrollTop: $(hash).offset().top
            },{
             duration: 'slow',
             easing: 'swing'
            });
         }
        }
      }
    }
  }
  scrollToAddressForm(id){
    $('html,body').animate({
      scrollTop: $(id).offset().top-10
    },{
      duration: 'slow',
      easing: 'swing'
    });
  }
  
  scrollToDeliverytarget(id){
    $('html, body').animate({
      scrollTop: $(id).offset().top-15
    }, 100);
  }
  
  
  scrollToTargetFindStoreElement(id){
    const _pos=$(id).offset().top - 1.5;
    $('html, body').animate({
      scrollTop: _pos
    },1000);
  }
  scrollToTargetElement(id){
    $('html, body').stop().animate({
      scrollTop: $(id).offset().top - 200
    }, 500);
  }
  scrollToElWithinC(container,id){
    if($(container)[0]){

          const _elmnt=$(id).offset();
          $(container).animate({
            scrollTop: $(container).scrollTop() + _elmnt.top - $(container).offset().top
          }, {
            duration: 'slow',
            easing: 'swing'
          });

    }
  }

  getStoreData(name){
    if (isPlatformBrowser(this.platformId)) {
     if(localStorage.getItem(name)){
       return localStorage.getItem(name);
     }
  }
  }
  updateCart(value) {
    const that = this;
    if (value == "updateCart") {
      that.sendMessage({
        updateCart: true,
        showCartPopUp: true
      });
    }
  }
  sendMessage(message: any) {
    if (message.siteid) {
      this.catalogVersionId = message.siteid;
    } else {
      this.subject.next(message);
    }
  }
  triggerCreditCardFlag(flag:boolean){
    this.creditCardFlag.next(flag);
    }
  // clearMessage() {
  //   this.subject.next(message);
  // }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  nestedCopy(array) {
    return JSON.parse(JSON.stringify(array));
  }

  setupEntryStream(data){
    const _nonPickerBundle=[];
    const cart=this.nestedCopy(data);
    cart['bundles']=[];
    if(cart.entries){
    const entries= _.filter(cart.entries, function(o) {
         return (o.bundleNo==0&& !o.pickAgain);
     });
    const bundleNo =_.groupBy(cart.entries, 'bundleNo');
    if(cart.GWPPromotionList){
       
      cart.GWPPromotionList.forEach((item:any) => {
        const pickerentries= _.filter(cart.entries, function(o) {
          return ((o.bundleNo==0&& o.pickAgain) && item.promotionCode ==  o.promotionCode);
      });
    const _copyOfpickerentries:any = JSON.parse(JSON.stringify(pickerentries));
    const _copyOfpickerBundle :any = JSON.parse(JSON.stringify(pickerentries));
     _copyOfpickerBundle.forEach((entry)=>{
      if((entry.pickAgain) && (entry.pickerQuanity != entry.quantity)) {
         _nonPickerBundle.push(entry);
      }
    });

          if(_copyOfpickerentries.length !=0){
            const _pickeTileinfo:Array<any> = _copyOfpickerBundle.filter((entry)=>{
               return ((entry.pickAgain) && (entry.pickerQuanity === entry.quantity)  &&( item.promotionCode ==  entry.promotionCode));
            });
            if(_pickeTileinfo.length !=0 ){
               _pickeTileinfo[0]['bundleTemplateId'] = 'pickagainBundle';
             }
             const _pickbundle={   
                bundleTitleName:(_pickeTileinfo[0])?_pickeTileinfo[0].promotionName:_copyOfpickerentries[0].promotionName,
                bundleImage:this.getBundleImage('pickagainBundle'),  
                bundleJPGImage:this.getBundleImageJpg('pickagainBundle'),         
                entryNumber:(_pickeTileinfo[0])?_pickeTileinfo[0].entryNumber:_copyOfpickerentries[0].entryNumber,
                product:_copyOfpickerentries,
                bundleTemplateId:'pickagainBundle',
                promotionCode:(_pickeTileinfo[0])?_pickeTileinfo[0].promotionCode:_copyOfpickerentries[0].promotionCode,
                allowedQuantity:item.allowedQuantity,
                enablePOPTile:(item.isVoucherApplied)?true:false,
                bundleNo:0,
                quantity:1,
                isBundle:true,
                pickAgain:true,
                disableCounterAction:true,
                promotionMessage:(_pickeTileinfo[0])?_pickeTileinfo[0].promotionMessage:_copyOfpickerentries[0].promotionMessage,
                discountedProductPrice:(_pickeTileinfo[0])?_pickeTileinfo[0].discountedProductPrice:_copyOfpickerentries[0].discountedProductPrice
              }
              _pickbundle['stock'] = (_pickbundle)?this.getBundleStock(_pickbundle):{stockLevelStatus:'inStock'};
              entries.push(_pickbundle);
            }
      });
   }
        for(let k in bundleNo){
        if(k != '0'){
          const _entryNumber:any=_.maxBy(bundleNo[k], function(o) { return o.entryNumber; });
          if(bundleNo[k][0]['bundleTemplateId']== "Pick And Mix_Pick2" || bundleNo[k][0]['bundleTemplateId'].indexOf("2") !=-1){
              const bundle={
                bundleTitleName:this.getBundleContent(bundleNo[k][0]),
                entryNumber:_entryNumber.entryNumber,
                product:bundleNo[k],
                bundleImage:this.getBundleImage(bundleNo[k][0]['bundleTemplateId']),  
                bundleJPGImage:this.getBundleImageJpg(bundleNo[k][0]['bundleTemplateId']),  
                bundleTemplateId:bundleNo[k][0]['bundleTemplateId'],
                bundleNo:bundleNo[k][0]['bundleNo'],
                bundlePrice:bundleNo[k][0]['bundlePrice'],
                promotionCode:bundleNo[k][0].promotionCode,
                enablePOPTile:true,
                isBundle:true,
                giveAway:true,
                fragrance:true,
                disableCounterAction:true
                };
                bundle['stock']= (this.getBundleStock(bundle))?this.getBundleStock(bundle):{stockLevelStatus:'inStock'};
              if(bundle.bundleTemplateId){
                  bundle['quantity']=_entryNumber.quantity ;
              } 
              bundle['productQty'] = this.setProductQuantity(bundleNo[k][0].product);
              entries.push(bundle);
          } else {
            const bundle={
              bundleTitleName:this.getBundleContent(bundleNo[k][0]),
              entryNumber:_entryNumber.entryNumber,
              product:bundleNo[k],
              bundleImage:this.getBundleImage(bundleNo[k][0]['bundleTemplateId']),  
              bundleJPGImage:this.getBundleImageJpg(bundleNo[k][0]['bundleTemplateId']), 
              bundleTemplateId:bundleNo[k][0]['bundleTemplateId'],
              bundleNo:bundleNo[k][0]['bundleNo'],
              bundlePrice:bundleNo[k][0]['bundlePrice'],
              promotionCode:bundleNo[k][0].promotionCode,
              enablePOPTile:true,
              isBundle:true
              }
              bundle['stock']= (this.getBundleStock(bundle))?this.getBundleStock(bundle):{stockLevelStatus:'inStock'};
            if(bundle.bundleTemplateId){
              const _products=bundle.product.filter((obj)=>{
                return !obj.product.isSample;
              })
              if(bundle.bundleTemplateId.includes('PickandMix')){
                if(bundleNo[k][0]['bundleTemplateId'].indexOf("3") !=-1){
                  bundle['quantity']=this.getBundleQuantity(_products)/3;
                }else if(bundleNo[k][0]['bundleTemplateId'].indexOf("6") !=-1){
                  bundle['quantity']=this.getBundleQuantity(_products)/6;
                }
              }else if(bundle.bundleTemplateId.includes('GiftBuilder')){
                const giftBuildrQty = bundle.bundleTemplateId.charAt(bundle.bundleTemplateId.length - 1);
                //for calculating bundle quantity
                bundle['quantity']=this.getBundleQuantity(_products)/giftBuildrQty;
                _products.map(eachProduct=>{
                  eachProduct["eachItemsInGiftBuilder"] = [];
                  //this.getBundleProductQuntity(bundle['quantity'] - for calculating the individual quyantity of the items in gift builder
                  for(let i=0;i<this.getBundleProductQuntity(bundle['quantity'],eachProduct);i++){
                    eachProduct["eachItemsInGiftBuilder"].push(eachProduct.product.productDisplayName);
                  }
                });
                const giftBuilderSample = bundle.product.filter((obj)=>{
                  return obj.product.isSample;
                })
                _products.push(giftBuilderSample[0]);
                bundle['product']=_products;
              }
             } 
             bundle['productQty'] = this.setProductQuantity(bundleNo[k][0].product);
             entries.push(bundle);
          }
        }
        }
        entries.map((prod)=>{
          if(!prod.isBundle){
             if(prod.pickAgain){
                prod['disableCounterAction']=true;
             }else if(prod.giveAway){
               prod['disableCounterAction']=true;
            }else{
                prod['disableCounterAction']=false;
             }
          }
     });

    if( _nonPickerBundle ){ 
        _nonPickerBundle.forEach((element:any) => {
            element.disableCounterAction = false;
            delete  element.promotionMessage ;
            delete  element.discountedProductPrice;
            element.pickAgain=false;
            element.quantity = element.quantity - element.pickerQuanity;
            entries.push(element);
      });
 }
  cart.entries=entries;
  cart.entries.sort((a,b)=>{
    return a.entryNumber - b.entryNumber;
  });
  } 
  return cart;
}
getBundleImage(bundleTemplateId) {
  if (bundleTemplateId) {
    if (bundleTemplateId.indexOf("6") != -1) {
      return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel6_uk_pick-mix-travel-6_image_01?$smallImg$&amp;fmt=webp";
    } else if (bundleTemplateId.indexOf("3") != -1)  {
      return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=webp";
    } else if( bundleTemplateId == 'pickagainBundle' ){
     return 'https://media.moltonbrown.co.uk/i/moltonbrown/product-picker-promo-image?$smallImg$&amp;fmt=webp';
   } else{
      return "https://media.moltonbrown.co.uk/i/moltonbrown/FFDummySKU_uk_Fragrance-Finder-Samples_image_01?$thImg$&fmt=webp"
    }
  }
  return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=webp";
}
getBundleImageJpg(bundleTemplateId){
  if(bundleTemplateId){
    if(bundleTemplateId.indexOf('6') !=-1 ){
      return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel6_uk_pick-mix-travel-6_image_01?$smallImg$&amp;fmt=jpg"
    }else if(bundleTemplateId.indexOf('3') !=-1 ){
      return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=jpg"
    } else if( bundleTemplateId == 'pickagainBundle' ){
         return 'https://media.moltonbrown.co.uk/i/moltonbrown/product-picker-promo-image?$smallImg$&amp;fmt=jpg';
    }else{
      return "https://media.moltonbrown.co.uk/i/moltonbrown/FFDummySKU_uk_Fragrance-Finder-Samples_image_01?$thImg$&fmt=jpg"
    }
  }
    return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=jpg"
  
 }
getBundleQuantity(entry){
   var total = 0;
    for(var i=0;i<entry.length;i++){
      total += parseInt(entry[i].quantity);
  }
  return total
}

getBundleStock(bundle){
  const _stock=bundle.product.filter((item)=>{
    return (item.product.stock&&item.product.stock.stockLevelStatus !='inStock')
  });
  if( _stock.length !=0  ){
   return  _stock[0].product.stock;
  }else{
    return bundle.product[0].product.stock;
  }
}
loadScript(src) {
  return new Promise((onFulfilled, onRejected) => {
    try {
      const renderer = this.rendererFactory.createRenderer(this.dom, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
      });
      let loaded;
      const script = renderer.createElement('script');
      const head = this.dom.head;

      if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
      }
      renderer.setAttribute(script,'type','text/javascript');
      renderer.setAttribute(script, 'src', src);
      
      renderer.appendChild(head, script);
      script.onreadystatechange = script.onload = () => {
        if (!loaded) {
            onFulfilled(script);
        }
        loaded = true;
      };
      script.onerror = function() { };
    } catch (e) {
    }
  });
}
isMyScriptLoaded(url) {
  const scripts = this.dom.getElementsByTagName('script');
  for (let i = scripts.length; i--;) {
      if (scripts[i].src == url) return true;
  }
  return false;
}
sessionTime:number=30;
curTime:string;
alarmTime:any;

  IfZero(num) {
  return ((num <= 9) ? ("0" + num) : num);
  }

checkCardNumber(number){
  return true;
}
luhn_checksum(code) {
  var len = code.length
  var parity = len % 2
  var sum = 0
  for (var i = len-1; i >= 0; i--) {
      var d = parseInt(code.charAt(i))
      if (i % 2 == parity) { d *= 2 }
      if (d > 9) { d -= 9 }
      sum += d
  }
  return sum % 10
}

getDigit(number){ 
    if (number < 9) 
        return number; 
    return number / 10 + number % 10; 
} 

prefixMatched(number, d) 
{ 
    return this.getPrefix(number, this.getSize(d)) == d; 
} 
getPrefix(number:string, k) 
{ 
    if (this.getSize(number) > k)  
    { 
        const num:string = number + ""; 
        return parseInt(num.substring(0, k)); 
    } 
    return number; 
}
getSize(d) 
{ 
    const num = d + ""; 
    return num.length; 
} 
runExperience(tag){
 
}
getBundleContent(text) {
  let picknmix3,picknmix6,giftbuilder;
  const baseSite=this.catalogVersion;
  const gbCheck = text.bundleTemplateId.split('_')[0];
  if(baseSite){
  if(baseSite.isoCode == "DE"){
   picknmix6="Pick & Mix für die Reise x 6";
   picknmix3="Pick & Mix für die Reise x 3";
  } else{
   picknmix6="Pick & Mix Travel x 6";
   picknmix3="Pick & Mix Travel x 3";
   giftbuilder="1 X Gift Builder";
  }
 }
  if (text.bundleTemplateId.indexOf("6") != -1 && gbCheck != "GiftBuilder") {
    return picknmix6;
  } else   if (text.bundleTemplateId.indexOf("3") != -1 && gbCheck != "GiftBuilder") {
    return picknmix3;
  }else   if (gbCheck == "GiftBuilder") {
    return giftbuilder;
  }
  else {
    return "Fragrance Finder Samples";
  }
  return picknmix3;
}
setCatalogData(data:CatalogData) {
  this.catalogData.next(data);
 }
 getCatalogData(): Observable<CatalogData> {
  return this.catalogData.asObservable();
 }
 saveCard(flag: boolean) : void{
  this.saveCardFlag.next(flag)
 }
 saveCardSelected(flag: boolean): void {
  this.isSaveCardSelected = flag;
 }
 getSaveCardSelected() : boolean{
  return this.isSaveCardSelected;
 }
 hidePaymentOptions(flag): void {
  this.paymentOptionsFlag.next(flag)
 }
 setProductQuantity(product) {
    if (product.stock && product.stock.stockLevel) {
      let stockLevel = product.stock.stockLevel;
      if (stockLevel != 0 && stockLevel < 10)
        return Array.from(
          { length: stockLevel },
          (_, i) => i + 1
        );
      else if (stockLevel != 0)
        return Array.from(
          { length: 10 },
          (_, i) => i + 1
        );
    }
}
getBundleProductQuntity(entryQty,individualItem){
   return individualItem.quantity/entryQty;
}
countMiniCartQty(items){
  let totalQty = 0;
  items.forEach((item)=>{
    totalQty += item.quantity;
  });
  return totalQty;
}
}

import { Injectable } from "@angular/core";
/* import { ContentClient } from "dc-delivery-sdk-js"; */
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
//import { HtmlRenderer, Parser } from 'commonmark';
import { map,catchError } from 'rxjs/operators';
import {throwError,of } from 'rxjs';

/* const client = new ContentClient({
  hubName: "moltonbrown",
}); */

@Injectable({
  providedIn: "root",
})
export class AmplienceService {
  private headers: HttpHeaders;
  http: HttpClient;
  constructor(http: HttpClient) {   
      this.http = http;
      this.headers = new HttpHeaders();
      this.headers.append('Content-Type', 'application/json');
  }


getContentByKey(key: string,isScheduled?:boolean,domain?:string) {
const cdnNewPath = isScheduled && domain  ?  `https://${domain}/content/key/${key}?depth=all&format=inlined` : `https://moltonbrown.cdn.content.amplience.net/content/key/${key}?depth=all&format=inlined`;
return this.http
        .get<any[]>(cdnNewPath).pipe(map(data => data,
            catchError(err => of(err.message))
        ));
}
getContentByID(id: string, domain:string) {
  const cdnNewPath = `https://${domain}/content/id/${id}?depth=all&format=inlined`;
  console.log("cdnNewPath", cdnNewPath);
return this.http
        .get<any[]>(cdnNewPath).pipe(map(data => data,
            catchError(err => of(err.message))
        ));
}

getContentByHierarchy(id: string,domain?:string) {
  const cdnNewPath =`https://moltonbrown.cdn.content.amplience.net/content/filter`;
  const body = {"filterBy": [{"path": "/_meta/hierarchy/parentId", "value": id}]}
  return this.http
          .post<any[]>(cdnNewPath,body).pipe(map(data => data,
              catchError(err => of(err.message))
          ));
}
getContentByHierarchyCursor(id: string,cursorId:string) {
  const cdnNewPath =`https://moltonbrown.cdn.content.amplience.net/content/filter`;
  const body = {"filterBy": [{"path": "/_meta/hierarchy/parentId", "value": id}],"page":{"cursor":cursorId}}
  return this.http
          .post<any[]>(cdnNewPath,body).pipe(map(data => data,
              catchError(err => of(err.message))
          ));
}
/*   getByContentId(slotId) {
    const response = client
      .getContentItemById(slotId)
      .then((content) => {
        return content.body
      })
      .catch((error) => {
        console.log("content not found for ID", error);
      });
      return response
  } */

/*   getByDeliveryKey(key) {
    const response =   client
      .getContentItemByKey(key)
      .then((content) => {
        return content.body
      })
      .catch((error) => {
        console.log("content not found for Key", error);
      });
      return response
  } */

/*   //Multiple Ids/Keys
  getContentsById(ids : string[]){
    const response = client.getContentItemsById(ids).then((content) => {
      return content
    })
    .catch((error) => {
      console.log("content not found for IDs", error);
    });
    return response
  } */

/*   getContentsByKey(keys :string[]){
    const response = client.getContentItemsByKey(keys).then((content) => {
      return content
    })
    .catch((error) => {
      console.log("content not found for Keys", error);
    });
    return response
  } */
  // commonmark to extract markdown text to HTML content

  // getMarkDown(str){
  //   if(str) {
  //  // str = typeof str !== "string" ? JSON.stringify(str): str;
  //   const reader = new Parser();
  //   const writer = new HtmlRenderer({safe: true});
  //   const parsed = reader.parse(str);
  //   const result = writer.render(parsed);
  //   return result
  //   }
  // }
}

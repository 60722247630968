import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {throwError, of, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PATH } from 'src/app/app.constant';
import { InterPolateUrlService } from 'src/app/services/commons/InterPolateUrl.service';
@Injectable({ providedIn: 'root' })
export class WishlistService  extends InterPolateUrlService {

    private headers: HttpHeaders;
    http: HttpClient;
    constructor(http: HttpClient) {
        super();
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');
    }
     getWishlists(baseSite,tokenId, email){
        let url=this.interpolateUrl( environment.API_PATH + PATH.WISHLISTS,{
            email:email,
            baseSite:baseSite.catalogversionId
        });
            if(baseSite.csAgent){
                url=url+ "?access_token=" + baseSite.agentToken
            }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization':'bearer  '+tokenId
            })
        };
        return this.http
            .get<any[]>(url,httpOptions).pipe(map(data => data,
                catchError(err => of(err.message))
            ));
    }
    addToWishList(baseSite,tokenId,email,code,wishListId){
        let url = this.interpolateUrl(environment.API_PATH +  PATH.addToWishList,{
            email:email,
            wishListId: wishListId,
            code:code,
            baseSite:baseSite.catalogversionId});
            if(baseSite.csAgent){
                url=url+ "&access_token=" + baseSite.agentToken
            }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+tokenId
            })
        };
        return this.http.post(url, JSON.stringify({}),httpOptions)
            .pipe(map(data => data,
                catchError(err => of(err.message))
            ));
     }
    createNewWishList(baseSite, email, param, tokenId){
        let url=this.interpolateUrl( environment.API_PATH + PATH.NEWWISHLIST,{
            email:email,
            baseSite:baseSite.catalogversionId,
            wishListName:param.wishListName,
            isDefaultWishList:param.isDefaultWishList

        });
            if(baseSite.csAgent){
                url=url+ "?access_token=" + baseSite.agentToken
            }
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization':'bearer  '+tokenId                   
                })
            };
      
        return this.http
            .post<any[]>(url,JSON.stringify({}),httpOptions ).pipe(map(data => data,
                catchError(err => of(err.message))
            ));
    }
    setDefaultWishlists(baseSite,tokenId, email, wishListID){
        let url=this.interpolateUrl( environment.API_PATH + PATH.SETDEFAULTWISHLIST,{
            email:email,
            baseSite:baseSite.catalogversionId,
            wishListID : wishListID
        });
            if(baseSite.csAgent){
                url=url+ "?access_token=" + baseSite.agentToken
            }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization':'bearer  '+tokenId
            })
        };
        return this.http
            .post<any[]>(url,{},httpOptions).pipe(map(data => data,
                catchError(err => of(err.message))
            ));
    }

    removeWishlist(baseSite,tokenId, email, wishListID){
        let url=this.interpolateUrl( environment.API_PATH + PATH.REMOVEWISHLIST,{
            email:email,
            baseSite:baseSite.catalogversionId,
            wishListID : wishListID
        });
            if(baseSite.csAgent){
                url=url+ "?access_token=" + baseSite.agentToken
            }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization':'bearer  '+tokenId
            })
        };
        return this.http
            .delete<any[]>(url,httpOptions).pipe(map(data => data,
                catchError(err => of(err.message))
            ));
    }
    updateWishlist(baseSite,tokenId, email, wishListID, newwishlistname){
        let url=this.interpolateUrl( environment.API_PATH + PATH.UPDATEWISHLIST,{
            email:email,
            baseSite:baseSite.catalogversionId,
            wishListID : wishListID,
            newwishlistname : newwishlistname
        });
            if(baseSite.csAgent){
                url=url+ "?access_token=" + baseSite.agentToken
            }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization':'bearer  '+tokenId
            })
        };
        return this.http
            .put<any[]>(url,{},httpOptions).pipe(map(data => data,
                catchError(err => of(err.message))
            ));
    }
    removeWishListProduct(baseSite,tokenId,email,productCode,wishListID){
        ///users/:email/wishlist/:wishListID/removeEntry/?code=:productCode
        let url = this.interpolateUrl(environment.API_PATH +  PATH.REMOVE_WISHLIST,{
            email:email,
            productCode:productCode,
            wishListID : wishListID,
            baseSite:baseSite.catalogversionId});
            if(baseSite.csAgent){
                url=url+ "&access_token=" + baseSite.agentToken
            }
     
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'bearer '+tokenId
            })
        };
        return this.http.delete(url,httpOptions)
        .pipe(map(data => data,
            catchError(err => of(err.message))
        ));
     }
    checkProdIsInAllLists(listOfWL){
        let productInAllLists : boolean = false;
        if(listOfWL.hasMultipleWishList){
            productInAllLists = listOfWL.listNameAndId.every(list=>{
                return list.productInThisList == true
            });
        }
        return productInAllLists;
    }
    shareWishList(baseSite,wishListID){
          const url = this.interpolateUrl(environment.API_PATH +  PATH.SHARE_WISHLIST,{
            wishlistID : wishListID,
            baseSite:baseSite.catalogversionId
        });
            console.log("url",environment.API_PATH,PATH.SHARE_WISHLIST,url)
            return this.http
        .get<any[]>(url).pipe(map(data => data,
            catchError(err => of(err.message))
        ));
           
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';
 import {InterPolateUrlService} from "../services/commons/InterPolateUrl.service";
import {of} from 'rxjs';
import {environment} from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class FeatureComponentService  extends InterPolateUrlService{
    private headers: HttpHeaders;
    http: HttpClient;
    constructor(http: HttpClient) {
        super();
        this.http = http;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');
    }
    verifyProduct(baseSite,categoryCode){        
        const url=`${environment.CS_CUSTOMER}kaowebservices/v2/${baseSite.catalogversionId}/products/search?query=:relevance:allCategories:${categoryCode}&pageSize=12&fields=FULL&sort=`;
          return this.http
        .get<any[]>(url).pipe(map(data => data,
          catchError(err => of(err.message))
      ));
      }
    getStaticContent(lang: string) {
      const langPath = `assets/contents/${lang || 'en'}.json`;
      return this.http
              .get<any[]>(langPath).pipe(map(data => data,
                  catchError(err => of(err.message))
              ));
    }
    getI8nContent(lang: string) {
        const langPath = `assets/i18n/${lang || 'en'}.json`;
        return this.http
                .get<any[]>(langPath).pipe(map(data => data,
                    catchError(err => of(err.message))
                ));
      }
}